export const mockGraphData = [
    {
        "date": "Jan",
        "income": 10000,
        "expenses": 8000,
        "startDate": {
            "seconds": 1640948400,
            "nanoseconds": 0
        },
        "endDate": {
            "seconds": 1643367599,
            "nanoseconds": 999000000
        }
    },
    {
        "income": 1026.3,
        "expenses": 1475.0900000000001,
        "endDate": {
            "seconds": 1645959600,
            "nanoseconds": 0
        },
        "startDate": {
            "seconds": 1643626800,
            "nanoseconds": 0
        },
        "date": "Feb"
    },
    {
        "expenses": 10000,
        "income": 8000,
        "date": "Mar",
        "endDate": {
            "seconds": 1648724399,
            "nanoseconds": 999000000
        },
        "startDate": {
            "seconds": 1646046000,
            "nanoseconds": 0
        }
    },
    {
        "income": 12000,
        "date": "Apr",
        "endDate": {
            "seconds": 1651319999,
            "nanoseconds": 999000000
        },
        "expenses": 5000,
        "startDate": {
            "seconds": 1648724400,
            "nanoseconds": 0
        }
    },
    {
        "endDate": {
            "seconds": 1656590399,
            "nanoseconds": 999000000
        },
        "startDate": {
            "seconds": 1653998400,
            "nanoseconds": 0
        },
        "expenses": 12000,
        "income": 8000,
        "date": "Jun"
    },
    {
        "expenses": 6000,
        "income": 14000,
        "date": "Jul",
        "startDate": {
            "seconds": 1656590400,
            "nanoseconds": 0
        },
        "endDate": {
            "seconds": 1659182399,
            "nanoseconds": 999000000
        }
    }
];