import { TableIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import React, { useEffect } from "react";
import { useState } from "react";
import { db } from "../firebaseSetup";
import { toCurrency } from "../utils/currency";
import { updateIncomeData, updateExpenseData } from "../utils/firestoreQueries";
import { getDateBySeconds, getUTCDate, getYearBySeconds } from "../utils/date";
import { CSVImport } from "./csvImport";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '../assets/datepicker.css';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

type Props = {
  SpendingData: any,
  UserId: string,
  StatementData: any,
  UpdateData: any
};

let objHeaders: any[] = [];

export const SpendingBreakdown: React.FC<Props> = ({
  SpendingData,
  UserId,
  StatementData,
  UpdateData
}) => {
  const [toggledIndex, setToggledIndex] = useState<number[]>([]);
  const [reRender, setReRender] = useState(false);
  const [addEntry, setAddEntry] = useState(false);
  const [entryIncome, setEntryIncome] = useState("");
  const [entryExpenses, setEntryExpenses] = useState("");
  const [entryDates, setEntryDates] = useState([new Date(), new Date()]);

  const removeData = (seconds: number) => {
    // get current plan
    let updatedStatements = SpendingData.filter(function (obj: { date: { seconds: number; } }) {
      return obj.date.seconds !== seconds;
    });

    // set document
    db.collection(`${UserId}`).doc("statements").set({ sources: updatedStatements }).then(() => {
      // update plan
      updateIncomeData(updatedStatements, UserId);
      updateExpenseData(updatedStatements, UserId);
    }).then(() => {
      UpdateData();
    });
  }

  const addManualEntry = () => {
    const newData = {
      startDate: new Date(entryDates[0]),
      endDate: new Date(entryDates[1]),
      date: new Date(),
      expenses: parseFloat(entryExpenses),
      income: parseFloat(entryIncome)
    }

    db.collection(`${UserId}`).doc("statements").get().then(
      (document) => {
        const updatedStatements = document.data()?.sources;
        updatedStatements.push(newData);
        db.collection(`${UserId}`).doc("statements").set({ sources: updatedStatements }).then(() => {
          updateIncomeData(updatedStatements, UserId);
          updateExpenseData(updatedStatements, UserId);
          setEntryIncome("");
          setEntryExpenses("");
          setAddEntry(false)
        }).then(() => {
          logEvent(analytics, 'spending_manual_added', { name: UserId.toString()});
          UpdateData();
        });
      }
    )

  }

  const renderTableHeader = (transactions: any) => {
    const headers = Object.keys(transactions[0] ?? {});
    //sort headers to have Date at the start, amount at the end
    headers.sort()
      .reduce((acc, key) => ({
        ...acc, [key]: transactions[key]
      }), {})

    headers.sort(function (x, y) { return x === "Date" ? -1 : y === "Date" ? 1 : 0; });
    headers.sort(function (x, y) { return x === "Amount" ? 1 : y === "Amount" ? -1 : 0; });

    //save headers to state
    objHeaders = headers;
    return (
      <thead className="bg-blue-700 text-white">
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="px-6 py-2 text-left text-xs font-medium uppercase">{header}</th>
          ))}
        </tr>
      </thead>
    )
  }

  const renderTableBody = (transactions: any) => {
    //loop through state 'headers' returning appropriate vals
    return (
      <tbody className="bg-white divide-y divide-gray-200">
        {transactions.map((transaction: any, index: any) => (
          <tr key={index}>
            {objHeaders.map((val, index: number) => {
              return (
                <td key={index} className="px-6 py-2 text-left text-xs font-medium text-gray-500 whitespace-normal overflow-ellipsis overflow-hidden">{`${transaction[val]}`}</td>
              )
            })}
          </tr>
        )
        )}
      </tbody>
    )
  }

  useEffect(() => {
    setReRender(false);
  }, [reRender])

  const rowClick = (rowIndex: any) => {
    let currentToggleList = toggledIndex;
    if (currentToggleList.includes(rowIndex)) {
      currentToggleList.splice(currentToggleList.indexOf(rowIndex), 1)
    } else {
      currentToggleList.push(rowIndex)
    }

    setToggledIndex(currentToggleList);
    setReRender(true);
  }

  const showIndex = (indexRef: number) => {
    return toggledIndex.includes(indexRef);
  }

  const handleEntryClick = () => {
    if (!addEntry) {
      setAddEntry(true)
    } else {
      //check fields

      //save fields
      addManualEntry()

    }
  }

  let yearsOfData: string[] = [];

  const addYearToMonth = (arr: any) => {
    arr.map((month: {
      year: string; startDate: { seconds: number; }
    }) => {
      month.year = getYearBySeconds(month.startDate.seconds);
    })
  }
  addYearToMonth(SpendingData);
  SpendingData.sort((a: { year: number; },b: { year: number; }) => (a.year < b.year) ? 1 : ((b.year < a.year) ? -1 : 0))
  
  const renderYear = (year: string) => {
    let returnedElm;
    if(!yearsOfData.includes(year)){
      returnedElm = (
        <>
          <tr>
            <th colSpan={5} className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              {year}
              </th>
            </tr>
            <tr className="bg-gray-50">
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Month
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right hidden sm:table-cell">
              Income
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right hidden sm:table-cell">
              Expenses
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
              Difference
            </th>
            <th scope="col" className=""></th>
          </tr>
        </>
      );
      yearsOfData.push(year);
    } 
    return returnedElm;
    
  }

  return (
    <>
      <h2 className="subpixel-antialiased text-sm text-blue-700 font-medium mt-4">Spending Breakdown</h2>
      <div>
      <table className="divide-y divide-gray-200 mt-4 w-full">
        <tbody className="bg-white divide-y divide-gray-200">
          {SpendingData && SpendingData.filter((x: { year: number; }) => { return x.year > new Date().getFullYear() - 2})
          .map((month: {
            year: string; income: number; expenses: number; startDate: { seconds: number }, endDate: { seconds: number }, date: { seconds: number }, transactions: any 
}, index: number) => {
            const totalTextColor = (month.income - month.expenses) < 0 ? 'text-red-600' : 'text-green-500';
            return (
              <React.Fragment key={index}>
                {!yearsOfData.includes(month.year) 
                  && renderYear(month.year)}

                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 text-left h-4">
                      <span className="float-left">{getDateBySeconds(month.startDate.seconds)} - {getDateBySeconds(month.endDate.seconds)}</span>
                      {month.transactions && (<button onClick={() => rowClick(index)} title="View Statement" className="hover:cursor-pointer hidden md:inline"><TableIcon className="w-5 ml-3 text-blue-700 hover:text-blue-800" /></button>)}
                    </div>

                  </td>
                  <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                    <div className="text-sm text-right font-medium text-blue-700">{toCurrency(month.income)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                    <div className="text-sm text-right font-medium text-gray-400">{toCurrency(month.expenses)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className={`text-sm text-right font-medium ${totalTextColor}`}>{toCurrency(month.income - month.expenses)}</div>
                  </td>
                  <td className="text-center">
                    <button onClick={() => removeData(month.date.seconds)}><TrashIcon className="w-4 text-gray-400 hover:text-gray-700" /></button>
                  </td>
                </tr>
                {(month.transactions && showIndex(index)) && (
                  <tr className="hidden md:table-row">
                    <td colSpan={5}>
                      <table className="divide-y divide-gray-200 w-full max-w-4xl block overflow-scroll">
                        {renderTableHeader(month.transactions)}
                        {renderTableBody(month.transactions)}
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
          {addEntry && (
            <tr className="hidden sm:table-row">
              <td className="pl-4 whitespace-nowrap">
                <DateRangePicker
                  onChange={setEntryDates}
                  value={entryDates}
                  calendarIcon={null}
                  clearIcon={null}
                  locale="en-NZ"
                  className="text-sm text-gray-900 bg-gray-100 p-2 text-center"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input type="text" onBlur={e => setEntryIncome(e.target.value)} placeholder="Income" className="text-sm font-medium text-blue-700 p-2 w-full bg-gray-100 focus:outline-none text-right" />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input type="text" onChange={e => setEntryExpenses(e.target.value)} placeholder="Expenses" className="text-sm font-medium text-gray-400 p-2 w-full bg-gray-100 focus:outline-none text-right" />
              </td>
              <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                {(entryIncome && entryExpenses) ? (
                  <div className={`text-sm text-right font-medium ${(parseFloat(entryIncome) - parseFloat(entryExpenses)) < 0 ? 'text-red-600' : 'text-green-600'}`}>
                    {toCurrency(parseFloat(entryIncome) - parseFloat(entryExpenses))}
                  </div>) : (
                  <>
                    <div className="text-sm text-right font-medium text-gray-400">
                      0.00
                    </div>
                  </>
                )}
              </td>
              <td className="text-center">
                <button onClick={() => setAddEntry(false)}><XIcon className="w-4 text-gray-400" /></button>
              </td>
            </tr>
            
          )}
          {addEntry && (
            <React.Fragment>
            <tr className="sm:hidden md:hidden">
              <td colSpan={5} className="px-6 py-4 whitespace-nowrap">
                <DateRangePicker
                  onChange={setEntryDates}
                  value={entryDates}
                  calendarIcon={null}
                  clearIcon={null}
                  locale="en-NZ"
                  className="text-sm text-gray-900 bg-gray-100 p-2 w-full text-center"
                />
              </td>
            </tr>
            <tr className="sm:hidden md:hidden">
              <td colSpan={5} className="px-6 py-4 whitespace-nowrap">
                <input type="text" onBlur={e => setEntryIncome(e.target.value)} placeholder="Income" className="text-sm font-medium text-blue-700 p-2 w-full bg-gray-100 focus:outline-none text-right" />
              </td>
              </tr>
              <tr className="sm:hidden md:hidden">
              <td colSpan={5} className="px-6 py-4 whitespace-nowrap">
                <input type="text" onChange={e => setEntryExpenses(e.target.value)} placeholder="Expenses" className="text-sm font-medium text-gray-400 p-2 w-full bg-gray-100 focus:outline-none text-right" />
              </td>
              </tr>
              <tr className="sm:hidden md:hidden">
              <td colSpan={4} className="px-6 py-4 whitespace-nowrap">
                {(entryIncome && entryExpenses) ? (
                  <div className={`text-sm text-right font-medium ${(parseFloat(entryIncome) - parseFloat(entryExpenses)) < 0 ? 'text-red-600' : 'text-green-600'}`}>
                    {toCurrency(parseFloat(entryIncome) - parseFloat(entryExpenses))}
                  </div>) : (
                  <>
                    <div className="text-sm text-right font-medium text-gray-400">
                      0.00
                    </div>
                  </>
                )}
              </td>
              <td className="text-center">
                <button onClick={() => setAddEntry(false)}><XIcon className="w-4 text-gray-400" /></button>
              </td>
            </tr>
            </React.Fragment>
          )}
          </tbody>
        </table>
      </div>
      <div>
          <CSVImport UserId={UserId} UpdateData={UpdateData} StatementData={StatementData} />

          <button
            onClick={() => handleEntryClick()}
            disabled={(addEntry && (entryIncome === "" || entryExpenses === ""))}
            className={`mt-2 disabled:bg-gray-200 float-right rounded-md border shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm ${addEntry ? 'bg-blue-700 text-white' : 'bg-white text-blue-600 hover:text-blue-800 hover:border-gray-300'}`}>
            {addEntry ? "Save Entry" : "Manual Entry"}
          </button>
      </div>
    </>
  )
}