import { useEffect, useState } from "react";
import { db } from "../firebaseSetup";
import { toCurrency } from "../utils/currency";
import { SavingsModal } from "./savingsModal";

type Props = {
    UserId?: string
    AccountTitle: string,
    SavingsData: any,
    UpdatePlan: any
};

export const SavingsBalance: React.FC<Props> = ({
    UserId,
    AccountTitle,
    SavingsData,
    UpdatePlan
}) => {
    const [showModal, setShowModal] = useState(false);
    const [savingsTotal, setSavingsTotal] = useState(0);
    const toggleModal = (val: boolean) => {
        setShowModal(val);
    }

    const updateSavingsAccount = (account:any, updatedBalance: any) => {
        const updatedSavingsData = SavingsData;
        updatedSavingsData.forEach((savingsAccount:any) => {
            if(savingsAccount.name === account.name && parseInt(savingsAccount.balance) !== parseInt(updatedBalance)) {
                //update data
                savingsAccount.balance = parseFloat(updatedBalance.replace(',', ''));
                
                db.collection(`${UserId}`).doc("savings").update({
                    sources: updatedSavingsData
                }).then(() => {
                    UpdatePlan();
                });
            }
        })
    }

    useEffect(() => {
        const updatedSavings = SavingsData;
        let _savingsTotal = 0;

        updatedSavings && updatedSavings.length ? (
            updatedSavings.forEach((account: { balance: number }) => {
                setSavingsTotal(_savingsTotal += account.balance)
            })
        ): setSavingsTotal(0);

    }, [UserId, SavingsData])

    return (
        <div className="relative">
            <h2 className={`subpixel-antialiased text-xs text-gray-500 font-medium pl-1 ${savingsTotal === 0 && 'mb-4'}`}>{AccountTitle}</h2>
            {savingsTotal !== 0
                && (<h2 className="subpixel-antialiased text-2xl text-blue-700 font-medium mb-4 pl-1">{toCurrency(savingsTotal)}</h2>)
            }
            {SavingsData && (
                SavingsData.map((account: { name: string, balance: number }, index: number) => (
                    <div className="pb-2" key={index}>
                        <>
                            <h4 className="subpixel-antialiased text-xs text-gray-500 font-medium uppercase pl-1">{account.name}</h4>
                            <input onBlur={(e) => updateSavingsAccount(account, e.target.value)} className="text-md font-medium text-green-600 focus:bg-gray-100 hover:bg-gray-100 focus:outline-none px-2 py-1" defaultValue={toCurrency(account.balance)} />
                        </>
                    </div>
                )))}
            <button className="font-medium bg-blue-700 py-2 px-4 rounded-md text-white hover:bg-blue-800 text-xs font-medium align-middle" onClick={() => setShowModal(true)}>
                {savingsTotal !== 0 ? "Edit Accounts" : "Add Accounts"}
            </button>
            {SavingsData && (<SavingsModal IsOpen={showModal} ToggleModal={toggleModal} SavingsData={SavingsData} UserId={UserId} UpdatePlan={UpdatePlan} />)}
        </div>
    )
};
