import axios from "axios";

var coinGeckoIds: { [key: string]: string } = {
    btc: 'bitcoin',
    eth: 'ethereum',
    cardano: 'ada',
    ripple: 'xrp'
}

export const getCoinGeckoId = (symbol: string) => {
    return coinGeckoIds[symbol]
}

export const getCryptoPrice = async (symbol: string) => {
    return await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${getCoinGeckoId(symbol.toLowerCase())}&vs_currencies=nzd`);
}