import { useEffect, useState } from "react";
import { db } from "../firebaseSetup";
import { getCoinGeckoId, getCryptoPrice } from "../utils/crypto";
import { toCurrency } from "../utils/currency";
import { getDateBySeconds } from "../utils/date";
import { InvestmentModal } from "./investmentModal";

type Props = {
    UserId?: string
    AccountTitle: string,
    InvestmentData: any,
    UpdatePlan: any
};

export const InvestmentBalance: React.FC<Props> = ({
    UserId,
    AccountTitle,
    InvestmentData,
    UpdatePlan
}) => {
    const [showModal, setShowModal] = useState(false);
    const [investmentTotal, setInvestmentTotal] = useState(0);

    const toggleModal = (val: boolean) => {
        setShowModal(val);
    }

    const updateInvestAccount = (account:any, updatedBalance: any) => {
        const updatedInvestmentData = InvestmentData.sources;
        updatedInvestmentData.forEach((investmentAccount:any) => {
            if(investmentAccount.name === account.name && parseInt(investmentAccount.balance) !== parseInt(updatedBalance)) {
                //update data
                investmentAccount.price = parseFloat(updatedBalance.replace(',', ''));
                
                db.collection(`${UserId}`).doc("investments").update({
                    sources: updatedInvestmentData
                }).then(() => {
                    UpdatePlan();
                });
            }
        })
    }

    useEffect(() => {
        const updatedInvestments = InvestmentData ? InvestmentData.sources : [];
        let _investmentTotal = 0;

        updatedInvestments && updatedInvestments.length ? (
            updatedInvestments.forEach((investment: { price: number, units: number }) => {
                if (investment.units) {
                    setInvestmentTotal(_investmentTotal += (investment.price * investment.units))
                } else {
                    setInvestmentTotal(_investmentTotal += investment.price)
                }
            })
        ): setInvestmentTotal(0);

        InvestmentData && InvestmentData.sources.map((account: { type: string; symbol: any; }, index: string | number) => {
            if(account.type === "C"){
                getCryptoPrice(account.symbol).then((resp) => {
                    InvestmentData.sources[index].price = resp.data[getCoinGeckoId(account.symbol)].nzd
                });
            }
        })

        
    }, [UserId, InvestmentData])

    return (
        <div className="relative">
            <h2 className={`subpixel-antialiased text-xs text-gray-500 font-medium pl-1 ${investmentTotal === 0 && 'mb-4'}`}>{AccountTitle}</h2>
            {investmentTotal !== 0
                && (<h2 className="subpixel-antialiased text-2xl text-blue-700 font-medium mb-4 pl-1">{toCurrency(investmentTotal)}</h2>)
            }
            {InvestmentData && InvestmentData.sources && (
                InvestmentData.sources.map((account: { name: string, balance: number, type: string, symbol: string, units: number, price: number }, index: number) => (
                    <div className="pb-2" key={index}>
                        {account.type === "O" && (
                            <>
                                <h4 className="subpixel-antialiased text-xs text-gray-500 font-medium uppercase pl-1">{account.name}</h4>
                                <input onBlur={(e) => updateInvestAccount(account, e.target.value)} className="text-md font-medium text-green-600 focus:bg-gray-100 hover:bg-gray-100 focus:outline-none px-2 py-1" defaultValue={toCurrency(account.price)} />
                            </>
                        )}
                        {account.type === "C" && (
                            <>
                                <h4 className="subpixel-antialiased text-xs text-gray-500 font-medium uppercase pl-1">{account.symbol}</h4>
                                <div className="text-md font-medium text-green-600 px-2 py-1">{toCurrency(account.units * account.price)}</div>
                            </>
                        )}
                    </div>
                )))}
            <button className="font-medium bg-blue-700 py-2 px-4 rounded-md text-white hover:bg-blue-800 text-xs font-medium align-middle" onClick={() => setShowModal(true)}>
                {investmentTotal !== 0 ? "Edit Accounts" : "Add Accounts"}
            </button>
            {InvestmentData && (<InvestmentModal IsOpen={showModal} ToggleModal={toggleModal} InvestmentData={InvestmentData.sources} UserId={UserId} UpdatePlan={UpdatePlan} />)}
        </div>
    )
};
