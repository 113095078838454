import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCxA80iVPKhhhxHWDX4pCVLKq9Q-RpXBfM",
    authDomain: "auth.planyourpay.com",
    projectId: "sightspend",
    storageBucket: "sightspend.appspot.com",
    databaseURL: "https://sightspend.firebaseio.com",
    messagingSenderId: "541677332077",
    appId: "1:541677332077:web:8325c960564bef4df88164",
    measurementId: "G-4L57MT1KF6"
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});

export const signInWithGoogle = () => auth.signInWithPopup(provider);