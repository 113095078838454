import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon } from '@heroicons/react/outline'

import goalsGif from '../assets/goals.gif'; // Tell webpack this JS file uses this image
import discoverPlansGif from '../assets/discover_plans.gif'; // Tell webpack this JS file uses this image
import csvUploader from '../assets/csvUpload.gif'; // Tell webpack this JS file uses this image

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

type Props = {
  UserId: string
};

export const OnboardingModal: React.FC<Props> = ({
  UserId
}) => {
  
  const [showModal, setShowModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const cancelButtonRef = useRef(null);

  const renderTitle = (currentPage: number) => {
    switch (currentPage){
      case 0: {
        return <>Welcome to <span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span></>
      }
      case 1: {
        return "Creating Financial Goals"
      }
      case 2: {
        return "Discover Financial Plans"
      }
      case 3: {
        return "Uploading Data"
      }
      case 4: {
        return "We are currently in Alpha"
      }
      default: {
        break;
      }
    }
  }
  useEffect(() => {
  }, [currentPage])

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => setShowModal(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-50 rounded-lg text-left text-gray-600 overflow-hidden shadow-xl transform transition-all absolute left-4 bottom-4 right-4 lg:left-1/4 sm:my-8 sm:align-middle max-w-4xl xl:w-full xl:h-4/6 xl:inset-y-20 xl:left-1/4 xl:right-0">
              <div className="px-4 pt-5 pb-8 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-white-700 sm:mx-0 sm:h-10 sm:w-10">
                    <BeakerIcon className="h-8 w-10 text-blue-700" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-bold text-blue-700 text-center">
                      {renderTitle(currentPage)}
                    </Dialog.Title>
                    <div className="mt-2 text-sm pb-40">
                      {currentPage === 0 && (
                        <div>
                          <p className="mt-16">Before we get started. I would like thank your for checking out our Alpha product.</p>
                          <p className="mt-2"><span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> has been something I have personally been using over the last few years and has helped me significantly to get my Finances back in order.</p>
                          <p className="mt-2">This product is <b>not</b> a hardcode budgeting app. You will not be asked to categorise a chocolate bar here. There are plenty of those out there, this is not one of them.</p>
                          <p className="mt-4"><span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> was created when I really struggled to know what I should be doing with my money. I knew I should pay-off debt, I knew I should spend less then I make, but outside of that... I was really at a loss.</p>
                          <p className="mt-4">I spent months trying to figure out the 'best' ways to move forward, how I should be 'saving' and why... There wasn't a space or a resource that answered my questions and curiosity.</p>
                          <p className="mt-4">So I created <span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span>.</p>
                          <p className="mt-12">Before we get started, I want to show you some key features...</p>
                        </div>
                      )}
                      {currentPage === 1 && (
                        <div>
                          <div className="overflow-hidden mt-4">
                            <img src={goalsGif} alt="gif of creating new goal" className="w-2/3 mx-auto"/>
                          </div>
                          <p className="text-gray-700 mt-8">
                            Financial Goals play a big part in enabling you to get where you want to go.</p>
                          <p className="text-gray-700 mt-3">
                            Whether that be pay off the another debt or ticking off the next item in your personal Financial Plan.
                          </p>
                          <p className="text-gray-700 mt-3">
                            It drives you to succeed and is a vital piece of what makes <span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> work.
                          </p>
                          <p className="text-gray-700 mt-3">
                            Financial Goals are a place to put down whatever you want to achieve financially and to be reminded every time you take a look at your finances.
                          </p>
                          <p className="text-gray-700 mt-3">
                            We have already set you up with a <b>My Custom Plan</b>, this is a place where you can have the freedom to put down whatever you wish as Financial Goals.
                          </p>
                        </div>
                      )}
                      {currentPage === 2 && (
                        <div>
                          <div className="mt-4 rounded overflow-hidden">
                            <img src={discoverPlansGif} alt="gif of creating new goal" className="w-2/3 mx-auto"/>
                            <p className="text-gray-700 mt-4 text-sm">
                              With a huge variety of financial plans to choose from, depending on your goals, <span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> has a financial plan to help you get there.
                            </p>
                            <p className="text-gray-700 mt-2">Feel free to discover the variety on offer under the "Discover Financial Plans" section and be inspired, to take the next step in your journey.</p>
                            <p className="text-gray-700 mt-2">Financial Plans automatically set up your Financial Goals for you. In some cases you will want to update the Goal Targets to match your needs, but it is essentially giving you the blueprint for financial success.</p>
                          </div>
                        </div>
                      )}
                      {currentPage === 3 && (
                        <div>
                          <img src={csvUploader} alt="gif of creating new goal" className="w-1/2 mx-auto mt-4"/>
                          <div className="mt-4 rounded overflow-hidden">
                          <p className="text-gray-700 mt-4">
                            While manally adding your monthly Income and Expenses is totally fine and possible. We have built a way to remove the hassle of summing everything up and figuring the numbers out yourself.
                          </p>
                          <p className="text-gray-700 mt-4">
                            By simply uploading your bank statements, we will work out all the income sources, total the expenses and determine the date ranges, for you. It will be added into your statement data for your ease of viewing later.
                          </p>
                          </div>
                        </div>
                      )}
                      {currentPage === 4 && (
                        <div>
                          <div className="mt-4 rounded overflow-hidden">
                          <p className="text-gray-700 mt-4">
                            I am so excited to share <span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> with you. As this is in Alpha at this stage, there are a few things to consider.
                          </p>
                          <p className="text-blue-700 mt-8 text-bold">What does Alpha mean?</p>
                          <p className="text-gray-700 mt-2">A pre-release of software that is given out to a large group of users to try under real conditions.</p>
                          <p className="text-blue-700 mt-8 text-bold">What does that mean for me?</p>
                          <p className="text-gray-700 mt-2">
                            Things may change.<br /><br />As we get feedback on the product we may add, remove, and alter some of the current functionality.
                            The overall object of Alpha is to create the best product we can for you.
                          </p>
                          <p className="text-gray-700 mt-4">
                            With that in mind, we would love to hear from you. The things you like, the things you don't, or maybe some things you would like to see added in the future
                          </p>
                          <p className="text-gray-700 mt-4">
                            Simply use the Feedback tool on the right hand side of the page to get in touch.
                          </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse absolute bottom-0 left-0 right-0">
              <button
                  type="button"
                  className="sm:absolute left-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-200 focus:outline-none"
                  onClick={() => setShowModal(false)}
                  ref={cancelButtonRef}
                >
                  Skip
                </button>
                {currentPage > 0 && (
                  <button
                  type="button"
                  className="block md:hidden mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 text-base font-medium sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setCurrentPage(currentPage-1)}
                  >
                  Back
                </button>
                )}
                <button
                  type="button"
                  className="mt-3 w-full float-right inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-white hover:bg-blue-800 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    if (currentPage === 4){
                      setShowModal(false);
                      logEvent(analytics, 'onboarding_complete', { name: UserId});
                  } else {
                    setCurrentPage(currentPage+1)
                  }}}
                  >
                  {currentPage === 4 ? "Done" : "Next"}
                </button>
                {currentPage > 0 && (
                  <button
                  type="button"
                  className="hidden md:block mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 text-base font-medium sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setCurrentPage(currentPage-1)}
                  >
                  Back
                </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

