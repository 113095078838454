import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon, SparklesIcon, TrashIcon } from '@heroicons/react/outline'
import { db } from '../firebaseSetup';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

type Props = {
  IsOpen: boolean,
  ToggleModal: any,
  UserId: string,
  TogglePlan?: any,
  UpdatePlan?: any,
  FinancialPlanData? :any,
  SelectedPlan?: any
};

interface FinancialPlanProperties {
  id: any;
  name?: string;
  description?: string;
  steps?: any;
  categoryId?: number;
}

export const FinancialPlansModal: React.FC<Props> = ({
  IsOpen,
  ToggleModal,
  UserId,
  TogglePlan,
  UpdatePlan,
  FinancialPlanData,
  SelectedPlan
}) => {

  const [newFinancialPlanData, setNewFinancalPlanData] = useState<FinancialPlanProperties[]>([]);
  const [selected, setSelected] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const cancelButtonRef = useRef(null);

  const resetData = () => {
    setSelected(selectedPlan);
  }

  const addFinancialPlan = (selected: number) => {
    const updatedFinancialPlanData = FinancialPlanData;

    updatedFinancialPlanData.forEach((plan: any) => {
      plan.isSelected = false
    })
  
    updatedFinancialPlanData.push(
      {
        ...newFinancialPlanData[selected],
        isSelected: true
      }
    );

    db.collection(`${UserId}`).doc("plans").update({
      sources: updatedFinancialPlanData
    });
    
    TogglePlan(newFinancialPlanData[selected].name);
    logEvent(analytics, 'financial_plan_added', { name: newFinancialPlanData[selected].name});
    ToggleModal(false);
    resetData();

  }

  const removePlan = (id: string) => {
    let updatedFinancialPlanData = FinancialPlanData;

    updatedFinancialPlanData = updatedFinancialPlanData.filter((plan: { id: string; }) => {
      return plan.id !== id;
    });

    FinancialPlanData.forEach((plan: { id: string; }) => {
      if(plan.id === id){
        TogglePlan(updatedFinancialPlanData[0].name);
        updatedFinancialPlanData[0].isSelected = true;
      }
    })

    db.collection(`${UserId}`).doc("plans").update({
      sources: updatedFinancialPlanData
    });

    UpdatePlan();
  }

  const sortedFinancialData = (planData: any[]) => planData.sort((a: { categoryId: number; }, b: { categoryId: number; }) => (a?.categoryId > b?.categoryId) ? 1 : -1);

  const getFinancialPlans = () => {
    let temp: { id: string; }[] = [];

    db.collection('financialplans').get().then(
      (snapshot) => {
        // get current plan
        snapshot.forEach((financialPlan) => {
          temp.push({
            id: financialPlan.id,
            ...financialPlan.data()
          })
      })
        setNewFinancalPlanData(sortedFinancialData(temp));
      }
    ).then(() => {
      sortedFinancialData(temp).forEach((plan, index) => {
        if(plan.id === SelectedPlan.id){
          setSelectedPlan(index);
          setSelected(index);
        }
      })
    });
  }
  
  const checkSubscription = (planId:string) => {
    let subscriptionStatus = false;
    FinancialPlanData.forEach((plan: any) => {
      if(planId === plan.id){
        subscriptionStatus = true;
      }
    });
    return subscriptionStatus;
  }

  const renderPlanList = (newFinancialPlanData: any[]) => {
    // eslint-disable-next-line
    return newFinancialPlanData.map((plan, index) => {
      const showHide = plan.categoryId === selectedCategory ? 'block' : 'hidden lg:block';
      if(index === 0){
        return (
          <React.Fragment key={index}>
            <li key={index} className="bg-blue-600 text-white p-2 border-b"><button onClick={() => toggleCategory(1)}>Debt Reduction {selectedCategory !== 1 && <ChevronDownIcon className="w-4 float-right relative top-1 left-3 lg:hidden"/>}</button></li>
            <li key={plan.id} className={showHide}>
              <button className={`text-left text-xs w-full hover:cursor-pointer bg-gray-100 hover:bg-gray-200 px-3 py-2 text-gray-700 ${selected === index && 'bg-gray-200'}`} onClick={() => { setSelected(index); setSelectedCategory(plan.categoryId);}}> {plan.name}</button>
            </li>
          </React.Fragment>
        )
      }
      else if(index > 1 && plan.categoryId !== newFinancialPlanData[index-1].categoryId){
        switch (plan.categoryId) {
          case 2: {
            return (
              <React.Fragment key={index}>
                <li key={index} className="bg-blue-600 text-white p-2"><button onClick={() => toggleCategory(2)}>Full Financial Plans {selectedCategory !== 2 && <ChevronDownIcon className="w-4 float-right relative top-1 left-3 lg:hidden"/>}</button></li>
                <li key={plan.id} className={showHide}>
                  <button className={`text-left text-xs w-full hover:cursor-pointer bg-gray-100 hover:bg-gray-200 px-3 py-2 text-gray-700 ${selected === index && 'bg-gray-200'}`} onClick={() => { setSelected(index); setSelectedCategory(plan.categoryId);}}> {plan.name}</button>
                </li>
              </React.Fragment>
            );
          }
        }
      } else {
        return (
          <li key={plan.id} className={showHide}>
            <button className={`text-left text-xs w-full hover:cursor-pointer bg-gray-100 hover:bg-gray-200 px-3 py-2 text-gray-700 ${selected === index && 'bg-gray-200'}`} onClick={() => { setSelected(index); setSelectedCategory(plan.categoryId)}}> {plan.name}</button>
          </li>
        )
      }
    });
  }

  const toggleCategory = (categoryId:number) => {
    setSelectedCategory(categoryId);
  }

  useEffect(() => {
    getFinancialPlans();
    // eslint-disable-next-line
  },[SelectedPlan]);

  return (
    <Transition.Root show={IsOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => {ToggleModal(false); resetData();}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all absolute left-4 bottom-4 right-4 lg:left-1/4 sm:my-8 sm:align-middle max-w-4xl xl:w-full xl:h-4/6 xl:inset-y-20 xl:left-1/4 xl:right-0">
              <div className="h-full bg-blue-700 px-4 pt-5 pb-36 sm:p-6 sm:pb-8">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-700 sm:mx-0 sm:h-10 sm:w-10">
                    <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full text-white">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-white">
                      Discover Financial Plans
                    </Dialog.Title>
                    <div className="mt-2 text-sm">
                      <p>
                        We have assembled some common debt reduction techniques and recommended financial plan templates for you to navigate through and add to your list of financial goals.
                      </p>
                      <div className="bg-white mt-8 rounded overflow-hidden">
                      <div className="lg:flex w-full ">
                        <div className="lg:flex-none w-full lg:w-1/4 xl:w-56 bg-blue-600">
                          <ul>
                            {newFinancialPlanData &&
                              renderPlanList(newFinancialPlanData)
                            }
                          </ul>
                        </div>
                        <div className="lg:flex-auto w-full lg:w-3/4 xl:w-64 pt-4 pb-6 px-8 h-64 lg:h-96 overflow-y-scroll text-left">
                          <h2 className={`subpixel-antialiased text-xl text-blue-700 font-bold relative ${newFinancialPlanData[selected] && checkSubscription(newFinancialPlanData[selected].id) ? 'mb-1' : 'mb-4'}`}>
                            {newFinancialPlanData[selected] && newFinancialPlanData[selected].name}
                          </h2>
                          {newFinancialPlanData[selected] && checkSubscription(newFinancialPlanData[selected].id) && (
                              <div className='mb-4'>
                                <span className="block float-left bg-blue-700 text-white rounded-full px-2 py-1 text-xs font-normal">Selected</span>
                                <button onClick={() => removePlan(newFinancialPlanData[selected].id)} className="rounded-full px-2 py-1 text-xs font-normal"><TrashIcon className="w-4 text-gray-400 hover:text-gray-700"/></button>
                              </div>
                            )}
                          <div className="text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: `${newFinancialPlanData[selected] && newFinancialPlanData[selected].description}` }}></div>
                          
                          {newFinancialPlanData[selected] && newFinancialPlanData[selected].steps && (
                            <div className="text-sm text-gray-700 mt-12">
                              <h2 className="subpixel-antialiased text-lg text-blue-700 font-bold mb-4">Example Goals</h2>
                              <ul className="divide-y divide-blue-600">
                                <li className="bg-blue-700 text-white p-2 flex">
                                  <div className="w-1/2 flex-1">Goal</div>
                                  <div className="w-1/2 flex-1 text-right">Target</div>
                                </li>
                                {newFinancialPlanData[selected].steps && newFinancialPlanData[selected].steps.map((step: { name: any; target: number }, index: number) => {
                                  return (
                                  <li  key={index} className="flex p-2 py-1 text-gray-500">
                                    <div className="w-1/2 flex-1">{step.name}</div>
                                    {step.target !== 0 ? (
                                      <div className="w-1/2 flex-1 text-right">${step.target}</div>
                                    )
                                  : <div className="w-1/2 flex-1 text-right">-</div>}
                                  </li>
                                  )
                                })}
                              </ul>
                            </div>
                            )}
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse absolute bottom-0 left-0 right-0">
              <button
                  type="button"
                  className="sm:absolute left-0 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-white hover:bg-blue-800 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-200"
                  onClick={() => addFinancialPlan(selected)}
                  disabled={newFinancialPlanData[selected] && checkSubscription(newFinancialPlanData[selected].id)}
                >
                  Select Plan
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => { ToggleModal(false); resetData(); }}
                  ref={cancelButtonRef}
                >
                  Done
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

