import { CubeTransparentIcon } from '@heroicons/react/outline';
import dashboard from '../assets/dashboard.png';
import discoverPlans from '../assets/discover_plans.gif';
import goals from '../assets/goals.png';
import { RoadmapWidget } from '../components/roadmap';

export const Homepage = () => (
  <>
    <div className="overflow-auto">
      <div className="text-gray-700 py-12 text-center xl:w-3/4 m-auto">
        <h1 className="text-6xl font-bold mb-4 text-center">A <span className="font-semibold text-blue-700">Simple</span> way to view <span className="font-semibold text-blue-700">Finances</span></h1>
        <p className="mb-8 text-xl">Using <strong>Financial Goals</strong> alongside your Finances</p>
        <a href="/signup" className="py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-blue-800 text-white hover:bg-blue-600 hover:text-white focus:outline-none">
          Free for a Limited Time
        </a>
      </div>
    </div>
    <hr className="mt-10 mb-10 lg:hidden" />
    <div className="md:flex lg:mt-20">
      <div className="flex-1 text-gray-700 md:pr-16 lg:w-1/2">
        <h1 className="text-2xl font-bold mb-4 text-blue-700">Keeping track of your Spending</h1>
        <p className="mb-4">
        <span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> is for those that don't want to spend their time categorizing everything down to a chocolate bar. 
        </p>
        <p className="mb-4">
          You don't need to feel bad about your spending, just make sure your expenses don't exceed your income.
        </p>
        <p className="mb-4">
          Seeing your spending visualised helps understand where your saving efforts are paying off, or whether you need to course correct your spending.
        </p>
      </div>
      <div className="flex-none w-full md:w-1/2 text-center">
        <img src={dashboard} className="shadow-xl ring-1 bg-white ring-black ring-opacity-5 rounded w-full" alt="Spending Trends" />
      </div>
    </div>
    <hr className="mt-10 mb-10 lg:hidden" />
    <div className="md:flex flex-row-reverse lg:mt-20">
      <div className="w-full flex-1 text-gray-700 md:pl-16 lg:w-1/2 ">
      <h1 className="text-2xl font-bold mb-4 text-blue-700">It helps to be reminded</h1>
        <p className="mb-4">
          <span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> will remind you every time you log in, what your Financial Goals are. 
        </p>
        <p className="mb-4">
          There is something about ticking things off to get a true sense of progression, whether that's paying off debt or hitting investment goals.
        </p>
        <p className="mb-4">
          There is a tonne of advice out there on how to tackle your finances. We aren't here to get in the way. Use our simple Financial Goal list to create your own Financial 'todo' list and keep you on track.
        </p>
      </div>
      <div className="flex-none w-full md:w-1/2 text-center">
        <img src={goals} className="shadow-xl ring-1 bg-white ring-black ring-opacity-5 rounded mt-4" alt="Financial Plans" />
      </div>
    </div>
    {/* <hr className="mt-10 mb-10 lg:hidden" />
    <RoadmapWidget /> */}
    <hr className="mt-10 mb-10 lg:hidden" />
    <div className="md:flex">
      <div className="text-center text-gray-700 lg:py-16 w-full">
        <CubeTransparentIcon className="text-blue-700 w-10 m-auto mb-2" />
        <h1 className="text-2xl font-bold mb-4">Currently in <span className='text-blue-700'>Alpha!</span></h1>
        <p className="mb-8"><span className="font-semibold text-blue-700">Plan</span><span className="font-semibold text-gray-600">Your</span><span className="font-semibold text-blue-700">Pay</span> is currently in Alpha so would love for you to have a play around.<br /><br /> I would love to hear what you think.</p>
        <a href="/signup" className="bg-blue-700 hover:bg-blue-800 text-white px-8 py-4 rounded uppercase text-xs font-medium">Let's go</a>
      </div>
    </div>
    
  </>
);
