import { db } from "../firebaseSetup";

export const updateIncomeData = (incomeData: { income: number; }[], UserId: string) => {
  let totalIncome = 0;
  let averageIncome = 0;
  let balance = 0;

  incomeData.forEach((month: { income: number }, index: number) => {
    totalIncome += month.income;
    if (index === (incomeData.length - 1)) {
      balance = month.income;
    }
  })

  averageIncome = totalIncome / incomeData.length;

  //update income
  let updateIncomeObj = [{
    balance: balance,
    average: averageIncome
  }]

  db.collection(`${UserId}`).doc("income").update({ sources: updateIncomeObj });
}

export const updateExpenseData = (expenseData: { expenses: number; }[], UserId: string) => {
  let totalExpenses = 0;
  let averageExpenses = 0;
  let balance = 0;

  expenseData.forEach((month: { expenses: number; }, index: number) => {
    totalExpenses += month.expenses;
    if (index === (expenseData.length - 1)) {
      balance = month.expenses;
    }
  })

  averageExpenses = totalExpenses / expenseData.length;

  //update expenses
  let updateExpenseObj = [{
    balance: balance,
    average: averageExpenses
  }]

  db.collection(`${UserId}`).doc("expenses").update({ sources: updateExpenseObj });
}