import { Fragment, Key, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { toCurrency } from '../utils/currency';
import { db } from "../firebaseSetup";
import { ArrowSmDownIcon, InformationCircleIcon, SparklesIcon, TrashIcon } from '@heroicons/react/outline';
import { FinancialPlansModal } from './financialPlansModal';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

type Props = {
  FinancialPlanData: any,
  UserId: string,
  UpdatePlan: any
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const FinancialPlan: React.FC<Props> = ({
  FinancialPlanData,
  UserId,
  UpdatePlan
}) => {
  const [selected, setSelected] = useState(FinancialPlanData.find((plan: { isSelected: boolean }) => plan.isSelected === true));
  const [newGoal, setNewGoal] = useState(false);
  const [description, setDescription] = useState("");
  const [target, setTarget] = useState("");

  const [showModal, setShowModal] = useState(false);

  const toggleModal = (val: boolean) => {
    setShowModal(val);
  }

  const getSelectedData = (name: any) => {
    setSelected(FinancialPlanData.find((plan: { name: any; }) => plan.name === name));
  }

  const toggleSelectedData = (name: any) => {
    db.collection(`${UserId}`).doc("plans").get().then(
      (document) => {
        // get current plan
        const updatedPlans = document.data()?.sources;
        updatedPlans.forEach((plan: { name: any; isSelected: boolean; }) => {
          if (plan.name === name) {
            plan.isSelected = true
          } else {
            plan.isSelected = false
          }
        });
        // // set document
        db.collection(`${UserId}`).doc("plans").set({ sources: updatedPlans }).then(() => {
          // update plan
          UpdatePlan();
        });
      }
    )
    getSelectedData(name);
  }

  const updateGoalName = (planName: any, stepName: any, newName: string) => {
    if (stepName !== newName) {
      db.collection(`${UserId}`).doc("plans").get().then(
        (document) => {
          // get current plan
          const updatedPlans = document.data()?.sources;
          updatedPlans.forEach((plan: { name: any; steps: any; }) => {
            if (plan.name === planName) {
              plan.steps.forEach((step: { name: any; target: number; }) => {
                if (step.name === stepName) {
                  step.name = newName;
                }
              })
            }
          });
          // set document
          db.collection(`${UserId}`).doc("plans").set({ sources: updatedPlans }).then(() => {
            // update plan
            UpdatePlan();
          });
        }
      )
      getSelectedData(planName);
    }
  }

  const updateTargetAmount = (planName: any, stepName: any, target: string) => {
    db.collection(`${UserId}`).doc("plans").get().then(
      (document) => {
        // get current plan
        const updatedPlans = document.data()?.sources;
        updatedPlans.forEach((plan: { name: any; steps: any; }) => {
          if (plan.name === planName) {
            plan.steps.forEach((step: { name: any; target: number; }) => {
              if (step.name === stepName) {
                step.target = parseFloat(target.replace(',', ''));
              }
            })
          }
        });
        // set document
        db.collection(`${UserId}`).doc("plans").set({ sources: updatedPlans }).then(() => {
          // update plan
          UpdatePlan();
        });
      }
    )
    getSelectedData(planName);
  }

  const addFinancialGoal = (goal: string, target: number) => {
    db.collection(`${UserId}`).doc("plans").get().then(
      (document) => {
        const updatedPlans = document.data()?.sources;
        for (var i in updatedPlans) {
          if (updatedPlans[i].isSelected === true) {
            updatedPlans[i].steps.push({ name: `${goal}`, target: target, isComplete: false })
            break;
          }
        }
        db.collection(`${UserId}`).doc("plans").set({ sources: updatedPlans }).then(() => {
          logEvent(analytics, 'financial_goal_added', { name: goal});
          setTarget("");
          setDescription("");
          UpdatePlan();
        });
      }
    )
  }

  const removeFinancialGoal = (goalName: string) => {
    db.collection(`${UserId}`).doc("plans").get().then(
      (document) => {
        // get current plan
        const updatedPlans = document.data()?.sources;
        for (var i in updatedPlans) {
          if (updatedPlans[i].isSelected === true) {
            // find 'step' in array & remove step
            updatedPlans[i].steps = updatedPlans[i].steps.filter(function (obj: { name: string; }) {
              return obj.name !== `${goalName}`;
            });
            break;
          }
        }
        // set document
        db.collection(`${UserId}`).doc("plans").set({ sources: updatedPlans }).then(() => {
          logEvent(analytics, 'financial_goal_removed', { name: goalName});
          // update plan
          UpdatePlan();
        });
      }
    )
  }

  const toggleGoalCompletion = (goalName: string) => {
    db.collection(`${UserId}`).doc("plans").get().then(
      (document) => {
        // get current plan
        const updatedPlans = document.data()?.sources;
        for (var i in updatedPlans) {
          if (updatedPlans[i].isSelected === true) {
            // find 'step' in array
            const goalIndex = updatedPlans[i].steps.findIndex(((step: { name: string; }) => step.name === goalName));
            updatedPlans[i].steps[goalIndex].isComplete = !updatedPlans[i].steps[goalIndex].isComplete;
            break;
          }
        }

        // set document
        db.collection(`${UserId}`).doc("plans").set({ sources: updatedPlans }).then(() => {
          // update plan
          UpdatePlan();
        });
      }
    )
  }
  
  useEffect(() => {
    getSelectedData(selected.name)
  })
  return (
    <>
      <h2 className="subpixel-antialiased text-sm font-medium text-blue-700 mt-2 mb-4 float-left">Financial Goals</h2>
      {selected.id !== "myCustomPlan" && 
        <button className="text-white text-sm my-4 ml-2 hover:text-gray-50 antialiased" onClick={() => setShowModal(true)}>
          <div className="float-left mr-1">Plan Information</div>
          <InformationCircleIcon className="w-5 antialiased"/>
        </button>
      }
      {selected.steps && (
        <table className={`table-fixed w-full divide-y divide-gray-200 ${selected.id === "myCustomPlan" && "mt-4"}`}>
          <thead className="bg-blue-600 text-white">
            <tr>
              <th className="w-4 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"></th>
              <th scope="col" className="pl-0 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Goal
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-right">
              </th>
              <th scope="col" className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-right">
                Target
              </th>
              <th className="w-12 px-3 py-3"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {selected.steps.length === 0 && (
              <tr>
                <td colSpan={5} className="text-xs text-gray-500 p-3">
                  <ArrowSmDownIcon className="w-4 float-left mr-2 text-blue-700" />
                  <div className="float-left mr-2">Get started by Adding a Goal</div>
                  <ArrowSmDownIcon className="w-4 text-blue-700" />
                </td>
              </tr>
            )}
            {selected.steps.map((step: { isComplete: boolean; name: string; target: number }, index: number) => (
              <tr key={selected.id + index} className={classNames(step.isComplete ? 'bg-gray-50' : '')}>
                <td className="text-center">
                  <label className="inline-flex items-center">
                    <input type="checkbox" className="form-checkbox" checked={step.isComplete} onChange={() => toggleGoalCompletion(step.name)} />
                  </label>
                </td>
                <td className="pl-0 relative group" colSpan={2}>
                  <input
                    type="text"
                    className={classNames(
                      step.isComplete ? 'line-through bg-gray-50' : '',
                      'text-sm text-gray-900 p-2 w-full focus:bg-gray-100 hover:bg-gray-100 focus:outline-none'
                    )}
                    defaultValue={step.name}
                    onBlur={(e) => updateGoalName(selected.name, step.name, e.target.value)} />
                </td>
                {step.target !== 0 
                  ? ( <td className="py-2 relative group">
                  <input className={classNames(
                      step.isComplete ? 'bg-gray-50' : '', 
                      'px-2 py-2 text-right float-right focus:outline-none text-gray-500 text-sm focus:bg-gray-100 hover:bg-gray-100 w-full')} 
                    type="text" defaultValue={toCurrency(step.target)} onBlur={(e) => updateTargetAmount(selected.name, step.name, e.target.value)} />
                </td>)
                  :( <td className="py-2 relative group">
                  <input className="px-2 py-2 text-right float-right focus:outline-none text-gray-500 text-sm focus:bg-gray-100 hover:bg-gray-100" type="text" defaultValue={"-"} onBlur={(e) => updateTargetAmount(selected.name, step.name, e.target.value)} />
                </td>)
                }
                <td className="text-center">
                  <button onClick={() => removeFinancialGoal(step.name)}><TrashIcon className="w-4 text-gray-400 hover:text-gray-700" /></button>
                </td>
              </tr>
            ))}
            {newGoal && (
              <tr>
                <td className="text-center">
                  <input type="checkbox" className="form-checkbox" disabled />
                </td>
                <td colSpan={2}>
                  <input type="text" onChange={e => setDescription(e.target.value)} placeholder="Goal Description" className="focus:outline-none pl-0 px-6 py-4 whitespace-nowrap block w-full border-gray-100 border-r subpixel-antialiased text-sm text-gray-900" />
                </td>
                <td colSpan={2}>
                  <input type="text" onBlur={e => setTarget(e.target.value)} placeholder="Target" className="focus:outline-none px-8 py-4 whitespace-nowrap text-sm text-right font-medium text-gray-900 float-right" />
                </td>
              </tr>
            )}
            <tr>
              <td className="text-sm font-medium" colSpan={2}>
                {newGoal ? (
                  <button
                    type="button"
                    disabled={description === "" && target === ""}
                    className="disabled:bg-gray-200 disabled:text-gray-600 font-medium bg-blue-700 py-2 px-4 rounded-md text-white hover:bg-blue-800 text-sm font-medium align-middle mt-4"
                    onClick={() => { addFinancialGoal(description, parseInt(target)); setNewGoal(!newGoal) }}
                  >
                    <span className="align-middle">Save Goal</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="font-medium bg-blue-700 py-2 px-4 rounded-md text-white hover:bg-blue-900 text-sm font-medium align-middle mt-4"
                    onClick={() => setNewGoal(!newGoal)}
                  >
                    <span className="align-middle">Add Goal</span>
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <FinancialPlansModal IsOpen={showModal} SelectedPlan={selected} ToggleModal={toggleModal} UserId={UserId} TogglePlan={getSelectedData} FinancialPlanData={FinancialPlanData} UpdatePlan={UpdatePlan} />
    </>
  )
}