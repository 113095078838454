import { LineChart, Line, XAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { toCurrency } from '../utils/currency';
import { getMonthByIndex } from '../utils/date';
import { mockGraphData } from '../utils/mockData';

type Props = {
  GraphData: any
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const fontColor = payload[0].value < payload[1].value ? 'text-red-600' : 'text-green-600';
    return (
      <div className="custom-tooltip text-sm text-blue-700 font-medium bg-white shadow-md rounded-md px-4 py-2">
        <div className="font-medium">Income: {toCurrency(payload[0].value)}</div>
        <div className={`text-gray-400 font-medium ${fontColor}`}>Expenses: {toCurrency(payload[1].value)}</div>
      </div>
    );
  }

  return null;
};

const CustomLabel = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize={14} fill="#9ca3af" x={-15} y={10} className="font-medium uppercase">
        {getMonthByIndex(payload.value)}
      </text>
    </g>
  );
};

const renderGraph = (parsedData: any, showTooltip: boolean) => { 
  return (
  <ResponsiveContainer width="99%">
    <LineChart margin={{ top: 12, left: 20, right: 20, bottom: 22 }} data={parsedData}>
      <XAxis interval={0} axisLine={false} tickLine={false} dataKey="month"
        style={{
          fontSize: '0.8rem',
          textTransform: 'uppercase'
        }}
        tick={<CustomLabel />}
      />
      {showTooltip && <Tooltip content={<CustomTooltip />} />}
      <Line type="monotone" dataKey="income" dot={false} stroke="#1d4ed8" strokeWidth={2} />
      <Line type="monotone" dataKey="expenses" dot={false} stroke="#ccc" strokeWidth={2} />
    </LineChart>
  </ResponsiveContainer>
)};

export const MonthlySpendGraph: React.FC<Props> = ({
  GraphData
}) => {
  return (
    <div className="relative">
      <h2 className="subpixel-antialiased text-sm text-blue-700 font-medium">Monthly Spend</h2>
      <div className={`h-60 max-h-full flex items-center justify-center p-2 relative ${!GraphData.length && "opacity-20"}`}>
        {GraphData.length
          ? renderGraph(GraphData, true)
          : renderGraph(mockGraphData, false)
        }
      </div>
      <div className="absolute z-10 top-1/3 w-full text-xs text-center text-gray-700">
      {!GraphData.length && <div className="mr-4">Get started by adding data below</div>}
    
      </div>
        
    </div>
  )
};