import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition, Listbox } from '@headlessui/react'
import { AdjustmentsIcon, CheckIcon, TrashIcon, XIcon, SelectorIcon } from '@heroicons/react/outline'
import { db } from '../firebaseSetup';
import { getCoinGeckoId, getCryptoPrice } from '../utils/crypto';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

var axios = require("axios").default;

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  IsOpen: boolean,
  ToggleModal: any,
  InvestmentData: any,
  UserId?: string,
  UpdatePlan?: any
};

const investmentTypes = [
  // {
  //   id: 1,
  //   name: 'Stocks',
  //   type: 'S',
  // }, 
  {
    id: 2,
    name: 'Crypto',
    type: 'C',
  }, {
    id: 2,
    name: 'Other',
    type: 'O',
  }
];

export const InvestmentModal: React.FC<Props> = ({
  IsOpen,
  ToggleModal,
  InvestmentData,
  UserId,
  UpdatePlan
}) => {
  const [newGoal, setNewGoal] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [units, setUnits] = useState("");
  const [selected, setSelected] = useState(investmentTypes[1]);
  const [investmentType, setInvestmentType] = useState("O");
  const [isLoading, setIsLoading] = useState(false);

  const cancelButtonRef = useRef(null);

  const getStockPrice = async (symbol: string) => {
    const options = {
      method: 'GET',
      url: `https://murmuring-ocean-98798.herokuapp.com/https://api.financefeast.io/data/last?ticker=${symbol}`,
      headers: {
        'Authorization': 'Bearer pk_m1Y3AsI2fUvrevjoVEdX'
      }
    };

    return await axios.request(options);
  }

  const addInvestment = (type: string, symbol: string, units: number) => {
    const updatedInvestments = InvestmentData;
    setIsLoading(true);

    switch (type) {
      case "S": {
        if (symbol && units) {
          getStockPrice(symbol).then((resp) => {
            updatedInvestments.push({ symbol: `${symbol}`, units: units, type: "S", price: resp.data.data[0].close })
          }).then(() => {
            //update cache
            db.collection(`${UserId}`).doc("investments").update({
              sources: updatedInvestments
            });
          }).then(() => {
            UpdatePlan();
          })
        }

        break;
      }
      case "C": {
        if (symbol && units) {
          setIsLoading(true);
          getCryptoPrice(symbol).then((resp) => {
            console.log(resp.data[getCoinGeckoId(symbol)]);
            updatedInvestments.push({ symbol: `${symbol}`, units: units, type: "C", price: resp.data[getCoinGeckoId(symbol)].nzd })
          }).then(() => {
            //update cache
            db.collection(`${UserId}`).doc("investments").update({
              sources: updatedInvestments
            });
          }).then(() => {
            UpdatePlan();
          })
        }
        
        break;
      }
      case "O": {
        setIsLoading(true);
        if (symbol && units) {
          updatedInvestments.push({ name: `${symbol}`, type: "O", price: units });
          db.collection(`${UserId}`).doc("investments").update({
            sources: updatedInvestments
          }).then(() => {
            UpdatePlan();
          });
        }
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
    setSymbol("");
    setUnits("");
    logEvent(analytics, 'investment_added', { name: symbol});
    setNewGoal(!newGoal)
  }

  const removeInvestment = (symbol: string, investmentType: string) => {
    let updatedInvestments = InvestmentData;
    setIsLoading(true);

    switch (investmentType) {
      case "S": {
        updatedInvestments = updatedInvestments.filter((investment: { symbol: string; }) => {
          return investment.symbol !== symbol;
        });
        break;
      }
      case "C": {
        updatedInvestments = updatedInvestments.filter((investment: { symbol: string; }) => {
          return investment.symbol !== symbol;
        });
        break;
      }
      case "O": {
        updatedInvestments = updatedInvestments.filter((investment: { name: string }) => {
          return investment.name !== symbol;
        });
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

    db.collection(`${UserId}`).doc("investments").update({
      sources: updatedInvestments
    });
    setIsLoading(false);
    UpdatePlan();
  }

  const getPlaceholderText = () => {
    switch (investmentType) {
      case "S": {
        //statements; 
        return "AIR";
      }
      case "C": {
        return "BTC";
      }
      case "O": {
        return "Term Deposit";
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  useEffect(() => {
    setInvestmentType(selected.type)
    setIsLoading(false);
    // eslint-disable-next-line
  }, [setInvestmentType, selected.type, InvestmentData && InvestmentData.length])

  return (
    <Transition.Root show={IsOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => ToggleModal(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-700 sm:mx-0 sm:h-10 sm:w-10">
                    <AdjustmentsIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Investment Breakdown
                    </Dialog.Title>
                    {isLoading && (
                      <div className="absolute bottom-8 left-28">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                    )}
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Below you will find your current investment breakdown.
                      </p>
                      <div className="mt-4">
                        <table className="mb-6 w-full table-fixed text-gray-900 text-sm mr-2">
                            <thead className="bg-blue-600">
                              <tr>
                                <th className="p-2 text-left text-xs font-medium text-white uppercase text-left">Name</th>
                                <th className="p-2 text-left text-xs font-medium text-white uppercase text-right">Units</th>
                                <th className="p-2 text-left text-xs font-medium text-white uppercase pr-4 text-right">Value</th>
                                <th className="w-4 px-3 py-3"></th>
                              </tr>
                            </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                          { !InvestmentData.length && !newGoal && (
                              <tr>
                                <td colSpan={3} className="p-2 uppercase text-center text-xs text-gray-400">No Account Data</td>
                              </tr>
                            )}
                            {/* eslint-disable-next-line array-callback-return */}
                            {InvestmentData && InvestmentData.map((investment: { name: string, symbol: string; units: number, price: number, type: string, balance: number }, index: number) => {
                              if (investment.type === "S" || investment.type === "C") {
                                return (
                                  <tr key={index}>
                                    <td className="p-2 uppercase">{investment.symbol}</td>
                                    <td className="p-2 text-right">{investment.units}</td>
                                    <td className="text-right p-2 pr-4 text-blue-700">{(investment.units * investment.price).toFixed(2)}</td>
                                    <td>
                                      <button onClick={() => removeInvestment(investment.symbol, investment.type)}><TrashIcon className="w-4 text-gray-400 hover:text-gray-700" /></button>
                                    </td>
                                  </tr>
                                )
                              }
                              if (investment.type === "O") {
                                return (
                                  <tr key={index}>
                                    <td className="p-2 uppercase text-left">{investment.name}</td>
                                    <td className="p-2"></td>
                                    <td className="text-right p-2 pr-4 text-blue-700">{investment.price.toFixed(2)}</td>
                                    <td>
                                      <button onClick={() => removeInvestment(investment.name, investment.type)}><TrashIcon className="w-4 text-gray-400 hover:text-gray-700" /></button>
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                            {newGoal && (
                              <tr>
                                <td colSpan={4}>
                                  <table className="w-full table-fixed text-gray-900 text-sm mr-2">
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      <tr>
                                        <td>
                                          <Listbox value={selected} onChange={setSelected}>
                                            <div className="mt-1 relative">
                                              <Listbox.Button className="relative w-full pl-2 pr-8 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                <span className="flex items-center">
                                                  <span className="ml-1 block truncate">{selected.name}</span>
                                                </span>
                                                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                              </Listbox.Button>
                                              <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                  {investmentTypes.map((investment) => (
                                                    <Listbox.Option
                                                      key={investment.type}
                                                      className={({ active }) =>
                                                        classNames(
                                                          active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                          'cursor-default select-none relative py-2 pl-3 pr-9'
                                                        )
                                                      }
                                                      value={investment}
                                                    >
                                                      {({ selected, active }) => (
                                                        <>
                                                          <div className="flex items-center">
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                              {investment.name}
                                                            </span>
                                                          </div>

                                                          {selected ? (
                                                            <span
                                                              className={classNames(
                                                                active ? 'text-white' : 'text-blue-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                              )}
                                                            >
                                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                          ) : null}
                                                        </>
                                                      )}
                                                    </Listbox.Option>
                                                  ))}
                                                </Listbox.Options>
                                              </Transition>
                                            </div>
                                          </Listbox>
                                        </td>
                                        <td>
                                          <input type="text" onChange={e => setSymbol(e.target.value)} placeholder={getPlaceholderText()} className="w-full uppercase p-2 focus:bg-gray-100 focus:outline-none whitespace-nowrap subpixel-antialiased text-sm text-gray-900" />
                                        </td>
                                        <td>
                                          <input type="text" onChange={e => setUnits(e.target.value)} placeholder={investmentType === "O" ? "2,000" : "10"} className="p-2 focus:outline-none focus:bg-gray-100 whitespace-nowrap subpixel-antialiased text-sm text-right text-gray-900 w-full" />
                                        </td>
                                        <td className="w-8">
                                        <button
                                            type="button"
                                            className="shadow-sm p-1 text-base bg-blue-700 rounded font-medium hover:bg-blue-800 sm:mt-0 sm:ml-1 sm:w-auto sm:text-sm"
                                            onClick={() => {
                                              symbol && units ? addInvestment(investmentType, symbol, parseFloat(units)) : setNewGoal(false); 
                                            }}
                                          >
                                            {
                                              symbol && units ? <CheckIcon className="w-4 text-white" /> : <XIcon  className="w-4 text-white" />
                                            }
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="sm:absolute left-0 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-white hover:bg-blue-800 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setNewGoal(!newGoal)}
                >
                  Add Account
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => ToggleModal(false)}
                  ref={cancelButtonRef}
                >
                  Done
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

