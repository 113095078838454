import { SetStateAction, useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebaseSetup";
import { MonthlySpendGraph } from "../components/lineGraph";
import { AccountBalance } from "../components/accountBalance";
import { SpendingBreakdown } from "../components/spendingBreakdown";
import { FinancialPlan } from "../components/financialPlan";
import { InvestmentBalance } from "../components/investmentBalance";
import { SavingsBalance } from "../components/savingsBalance";
import { OnboardingModal } from "../components/onboardingModal";

import ReactAppzi from 'react-appzi';
import { FeedbackWidget } from "../components/feedbackWidget";
import { signInWithEmailAndPassword } from "firebase/auth";

interface StateProperties {
  expenses: number;
  income: number;
}

interface InvestmentStateProperties {
  lastUpdated: Date;
  sources: string;
}

interface GraphProperties {
  month: number;
  expenses: number;
  income: number;
}

export default function Dashboard() {
  const [incomeData, setIncomeData] = useState<StateProperties[]>([]);
  const [savingsData, setSavingsData] = useState<StateProperties[]>([]);
  const [investmentData, setInvestmentData] = useState<InvestmentStateProperties>();
  const [expensesData, setExpensesData] = useState<StateProperties[]>([]);
  const [statementData, setStatementData] = useState<StateProperties[]>([]);
  const [financialPlanData, setFinancalPlanData] = useState<StateProperties[]>([]);
  const [graphData, setGraphData] = useState<GraphProperties[]>([]);
  const [updateState, setUpdateState] = useState(false);

  const [showIntro, setShowIntro] = useState(false);

  const user = useContext(AuthContext);

  const getUserData = (userId: string, documentId: string, stateFunction: { (value: SetStateAction<StateProperties[]>): void; (arg0: any): any; }) => {
    db.collection(`${userId}`).doc(`${documentId}`).get().then(
      (document) => stateFunction(document.data()?.sources)
    )
  }

  const getInvestmentData = useCallback(() => {
    db.collection(`${user?.uid}`).doc('investments').get().then(
      (document) => {
        setInvestmentData({
          lastUpdated: document.data()?.lastUpdated,
          sources: document.data()?.sources
        })
      }
    )
  }, [user?.uid]);

  const getSavingsData = useCallback(() => {
    db.collection(`${user?.uid}`).doc('savings').get().then(
      (document) => {
        setSavingsData(document.data()?.sources)
      }
    )
  }, [user?.uid]);

  const getFinancialPlanData = () => {
    db.collection(`${user?.uid}`).doc('plans').get().then(
      (document) => {
        setFinancalPlanData(document.data()?.sources)
        setUpdateState(true);
      }
    )
  }
  

  const getGraphData = useCallback((data:any[]) => {
    var monthData:any[] = new Array(11);
    
    //sort
    data.length && data.sort((a: any, b: any) => {
      if (a.startDate.toDate() < b.startDate.toDate()) {
        return -1
      } else if (a.startDate.toDate() > b.startDate.toDate()) {
        return 1
      } else {
        return 0
      }
    });


    data.length && data.slice(-7).forEach(entry => {
    if(entry.transactions){
      let monthIndex = 0;
      entry.transactions.forEach((transaction: { Date: string; Amount: any }) => {
        monthIndex = new Date(entry.endDate.toDate()).getMonth(); 
        if(transaction.Amount > 0){
          monthData[monthIndex] = {
            month: monthIndex,
            date: entry.startDate.toDate(),
            income: monthData[monthIndex] ? monthData[monthIndex].income += Math.abs(parseFloat(transaction.Amount)) : Math.abs(parseFloat(transaction.Amount)),
            expenses: monthData[monthIndex] ? monthData[monthIndex].expenses : 0
          }
        } else {
          monthData[monthIndex] = {
            month: monthIndex,
            date: entry.startDate.toDate(),
            income: monthData[monthIndex] ? monthData[monthIndex].income : 0,
            expenses: monthData[monthIndex] ? monthData[monthIndex].expenses += Math.abs(parseFloat(transaction.Amount)) : Math.abs(parseFloat(transaction.Amount))
          }
        }
      })
    } else {
      // merge dates
      const monthIndex = entry.startDate.toDate().getMonth();
      monthData[monthIndex] = {
        month: monthIndex,
        year: entry.startDate.toDate().getYear(),
        income: monthData[monthIndex] ? monthData[monthIndex].income += entry.income : entry.income,
        expenses: monthData[monthIndex] ? monthData[monthIndex].expenses += entry.expenses : entry.expenses
      }
    }
  });

  monthData.forEach((month, index) => {
    if(month.income + month.expenses === 0){
      monthData.splice(index,1);
    }
  })

  monthData.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));

  
  return monthData.filter(() => true);
  }, []);

  //firestore data
  useEffect(() => {
    if (user?.uid) {
      getUserData(user.uid, 'income', setIncomeData);
      getUserData(user.uid, 'expenses', setExpensesData);
      getUserData(user.uid, 'statements', setStatementData);
      getUserData(user.uid, 'plans', setFinancalPlanData);
      getSavingsData();
      getInvestmentData();
    }

    setUpdateState(false);
    // eslint-disable-next-line
  }, [user?.uid, updateState, getInvestmentData, getSavingsData, financialPlanData && financialPlanData.length])

  useEffect(() => {
    setGraphData(statementData && getGraphData(statementData));
   }, [statementData, getGraphData]);

  useEffect(() => {
   let delay = setTimeout(() => setShowIntro(true), 1 * 1000);
   ReactAppzi.initialize('nxgDn');
    return () => {
      clearTimeout(delay);
    };
  }, []);

  const shouldShowIntro = () => {
    if (showIntro && (statementData && statementData.length === 0) 
      && (savingsData && savingsData.length === 0)
      && (investmentData && investmentData.sources.length === 0)
    ){
      return true;
    }else{
      return false;
    }
  }

  return user?.uid ? (
    <>
      {shouldShowIntro() && (
        <OnboardingModal UserId={user.uid}/>
      )}
      <div className="grid grid-flow-row sm:grid-flow-col gap-5 z-20 relative">
        <div className="row-span-1">
          <div className="px-4 py-4 bg-white shadow-md rounded-md sm:p-5 max-h-32 mb-4">
            <AccountBalance AccountTitle="Monthly Income" AccountData={incomeData} showAverage />
          </div>
          <div className="px-4 py-4 bg-white shadow-md rounded-md sm:p-5 max-h-32 mb-4">
            <AccountBalance AccountTitle="Monthly Expenses" AccountData={expensesData} showAverage reverseAvgGoal />
          </div>
          <div className="px-4 py-4 bg-white shadow-md rounded-md sm:p-4 mb-4">
            <SavingsBalance AccountTitle="Savings Acounts" SavingsData={savingsData} UserId={user.uid} UpdatePlan={getSavingsData} />
          </div>
          <div className="px-4 py-4 bg-white shadow-md rounded-md sm:p-4">
            <InvestmentBalance AccountTitle="Investments" InvestmentData={investmentData} UpdatePlan={getInvestmentData} UserId={user.uid} />
          </div>
        </div>
        <div>
          <div className="px-4 py-4 bg-white shadow-md rounded-md sm:p-5 mb-4">
          {financialPlanData && financialPlanData.length ? <FinancialPlan FinancialPlanData={financialPlanData} UserId={user.uid} UpdatePlan={getFinancialPlanData} /> : <div className="text-gray-300 text-sm mt-8  w-full text-center">Loading...</div>}
          </div>
          <div className="px-4 py-4 bg-white shadow-md rounded-md sm:p-5">
            {graphData && graphData.length ? <MonthlySpendGraph GraphData={graphData.length > 7 ? graphData.slice(-7) : graphData} /> : <div className="text-gray-300 text-sm mt-8  w-full text-center">Loading...</div>}
            <SpendingBreakdown SpendingData={statementData} UserId={user.uid} UpdateData={getFinancialPlanData} StatementData={statementData} />
          </div>
        </div>
      </div>
      
      <FeedbackWidget />
    </>
  ) : (<></>);
}