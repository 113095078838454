import firebase from "firebase/compat";
import { db } from "../firebaseSetup";

export async function setupAccount(user: firebase.auth.UserCredential) {
    const userId = user.user?.uid.toString();
    const documents = await db.collection("atHigEebNQVXd3PZpI1TgqCmTaC2").get();

    let writeBatch = db.batch();

    if (userId) {
        const destCollection = db.collection(userId);
        let i = 0;
        for (const doc of documents.docs) {
            writeBatch.set(destCollection.doc(doc.id), doc.data());
            i++;
            if (i > 400) {
                i = 0;
                await writeBatch.commit();
                writeBatch = db.batch();
            }
        }
        if (i > 0) {
            await writeBatch.commit();
        }
    }
}