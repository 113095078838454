import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AdjustmentsIcon, CheckIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import { db } from '../firebaseSetup';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

type Props = {
  IsOpen: boolean,
  ToggleModal: any,
  SavingsData: any,
  UserId?: string,
  UpdatePlan?: any
};

export const SavingsModal: React.FC<Props> = ({
  IsOpen,
  ToggleModal,
  SavingsData,
  UserId,
  UpdatePlan
}) => {
  const [newSavings, setNewSavings] = useState(false);
  const [name, setName] = useState("");
  const [balance, setBalance] = useState(0);

  const cancelButtonRef = useRef(null);

  const addSavings = (name: string, balance: number) => {
    const updatedSavings = SavingsData;

    if (name && balance) {
      updatedSavings.push({ name: `${name}`, balance: balance })
      db.collection(`${UserId}`).doc("savings").update({
        sources: updatedSavings
      });
      UpdatePlan();
      setBalance(0);
      setName("");
      setBalance(0);
      logEvent(analytics, 'savings_added', { name: name});
      setNewSavings(!newSavings)
    }
  }

  const removeSavings = (name: string) => {
    let updatedSavings = SavingsData;

    updatedSavings = updatedSavings.filter((savings: { name: string; }) => {
      return savings.name !== name;
    });

    db.collection(`${UserId}`).doc("savings").update({
      sources: updatedSavings
    });

    setBalance(0);
    UpdatePlan();
  }

  useEffect(() => {
  },[newSavings]);

  return (
    <Transition.Root show={IsOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => ToggleModal(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-700 sm:mx-0 sm:h-10 sm:w-10">
                    <AdjustmentsIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Savings Accounts
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Below you will find your current savings breakdown.
                      </p>
                      <div className="mt-4">
                        <table className="mt-6 w-full table-fixed text-gray-900 text-sm mr-2">
                          <thead className="bg-blue-600">
                            <tr>
                              <th className="p-2 text-left text-xs font-medium text-white uppercase text-left">Account Name</th>
                              <th className="p-2 text-left text-xs font-medium text-white uppercase pr-4 text-right">Balance</th>
                              <th className="w-4 px-3 py-3"></th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            { !SavingsData.length && !newSavings && (
                              <tr>
                                <td colSpan={3} className="p-2 uppercase text-center text-xs text-gray-400">No Account Data</td>
                              </tr>
                            )}
                            {SavingsData.map((savings: { name: string, balance: number }, index: number) => (
                              <tr key={index}>
                                <td className="p-2 uppercase text-left text-gray-600">{savings.name}</td>
                                <td className="text-right p-2 pr-4 text-blue-700">{savings.balance.toFixed(2)}</td>
                                <td>
                                  <button onClick={() => removeSavings(savings.name)}><TrashIcon className="w-4 text-gray-400 hover:text-gray-700" /></button>
                                </td>
                              </tr>
                            ))}
                            {newSavings && (
                              <tr>
                                <td colSpan={3}>
                                  <table className={`w-full table-fixed text-gray-900 text-sm`}>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      <tr>
                                        <td>
                                          <input type="text" onChange={e => setName(e.target.value)} placeholder="Emergency Fund" className="w-full uppercase p-2 focus:outline-none focus:bg-gray-100 whitespace-nowrap subpixel-antialiased text-sm text-gray-600" />
                                        </td>
                                        <td>
                                          <input type="text" onChange={e => setBalance(parseInt(e.target.value))} placeholder="2,000" className="p-2 focus:outline-none focus:bg-gray-100 whitespace-nowrap subpixel-antialiased text-sm text-gray-600 text-right float-right" />
                                        </td>
                                        <td className="w-8">
                                          <button
                                            type="button"
                                            className="shadow-sm p-1 text-base bg-blue-700 rounded font-medium hover:bg-blue-800 sm:mt-0 sm:ml-1 sm:w-auto sm:text-sm"
                                            onClick={() => { 
                                              name && balance ? addSavings(name, balance) : setNewSavings(false); 
                                            }}
                                          >
                                            {
                                              name && balance ? <CheckIcon className="w-4 text-white" /> : <XIcon  className="w-4 text-white" />
                                            }
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                  type="button"
                  disabled={newSavings}
                  className="disabled:bg-gray-200 sm:absolute left-0 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-white hover:bg-blue-800 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setNewSavings(!newSavings)}
                >
                  Add Account
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => ToggleModal(false)}
                  ref={cancelButtonRef}
                >
                  Done
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

