import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloudUploadIcon, InformationCircleIcon } from '@heroicons/react/outline'

import { CSVReader } from 'react-papaparse';
import { db } from '../firebaseSetup';
import React from 'react';
// import { getDayDateByLocale } from '../utils/date';
import { updateExpenseData, updateIncomeData } from '../utils/firestoreQueries';
import { Link } from 'react-router-dom';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

type Props = {
  UserId: string,
  UpdateData: any,
  StatementData: any
};

export const CSVImport: React.FC<Props> = ({
  UserId,
  UpdateData,
  StatementData
}) => {
  const [open, setOpen] = useState(false)
  const [transactionData, setTransactionData] = useState([]);
  const [income, setIncome] = useState<{ sourceName: string; amount: string; }[]>([])
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const cancelButtonRef = useRef(null)

  const parseTransactionData = (data: any) => {
    let _income: { sourceName: string; amount: string; }[] = [];
    let _totalIncome = 0;
    let _totalExpenses = 0;

    data.sort((a: any, b: any) => {
      if (new Date(a.data.Date) < new Date(b.data.Date)) {
        return -1
      } else if (new Date(a.data.Date) > new Date(b.data.Date)) {
        return 1
      } else {
        return 0
      }
    })

    data.forEach((transaction: { data: { Date: string; Amount: any; Payee: string; 'Other Party': string } }, index: any) => {
      if (transaction.data.Amount > 0) {
        _income.push({
          sourceName: transaction.data.Payee || transaction.data['Other Party'],
          amount: transaction.data.Amount
        })
        _totalIncome += Math.abs(parseFloat(transaction.data.Amount));
      } else {
        _totalExpenses += Math.abs(parseFloat(transaction.data.Amount));
      }
    })

    let splitStartDate = data[0].data.Date.split("/");
    let splitEndDate = data[data.length - 1].data.Date.split("/");
    let utcStartDate, utcEndDate;
    
    function getFormattedDate(date:any) {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
    
      return month + '/' + day + '/' + year;
      
    }

    // UTC FORMAT (year,month,day)
    if ((parseInt(splitEndDate[0]) > 12) && splitEndDate[0].length !== 4) {
      // DD/MM/YYYY
      utcStartDate = new Date(Date.UTC(parseInt(splitStartDate[2]), parseInt(splitStartDate[1]) -1, parseInt(splitStartDate[0]), 0, 0, 0))
      utcEndDate = new Date(Date.UTC(parseInt(splitEndDate[2]), parseInt(splitEndDate[1]) -1, parseInt(splitEndDate[0]), 0, 0, 0))

      setStartDate(getFormattedDate(utcStartDate));
      setEndDate(getFormattedDate(utcEndDate));
    } else if(splitEndDate[0].length === 4){
        // YYYY/MM/DD
        utcStartDate = new Date(Date.UTC(parseInt(splitStartDate[0]), parseInt(splitStartDate[1]) -1, parseInt(splitStartDate[2]), 0, 0, 0))
        utcEndDate = new Date(Date.UTC(parseInt(splitEndDate[0]), parseInt(splitEndDate[1]) -1, parseInt(splitEndDate[2]), 0, 0, 0))
        setStartDate(getFormattedDate(utcStartDate));
        setEndDate(getFormattedDate(utcEndDate));
        
      } else {
        // MM/DD/YYYY
        utcStartDate = new Date(Date.UTC(parseInt(splitStartDate[2]), parseInt(splitStartDate[0]) -1, parseInt(splitStartDate[1]), 0, 0, 0))
        utcEndDate = new Date(Date.UTC(parseInt(splitEndDate[2]), parseInt(splitEndDate[0]) -1, parseInt(splitEndDate[1]), 0, 0, 0))
        
        setStartDate(getFormattedDate(utcStartDate));
        setEndDate(getFormattedDate(utcEndDate));
    }

    setIncome(_income);
    setTotalIncome(_totalIncome);
    setTotalExpenses(_totalExpenses);
  };

  const handleOnDrop = (data: any) => {
    setTransactionData(data);
    parseTransactionData(data);
    logEvent(analytics, 'CSV_Upload_init', { name: UserId.toString()});
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data: any) => {
    resetData();
  };

  const renderTableHeader = (uploadedData: { data: any; }[]) => {
    const headers = Object.keys(uploadedData[0].data ?? {});
    return (
      <thead className="bg-gray-50">
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="w-4 px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase ">{header}</th>
          ))}
        </tr>
      </thead>
    )
  }

  const renderTableBody = (uploadedData: any[]) => {
    return (
      <tbody>
        {uploadedData.map((row: { data: any; }, index) => (
          <tr className="border" key={index}>
            {Object.values(row.data).map((text, index) => (
              <td key={index} className="w-4 px-6 py-2 text-left text-xs font-medium text-gray-500 whitespace-nowrap overflow-ellipsis overflow-hidden">{`${text}`}</td>
            ))}
          </tr>
        )
        )}
      </tbody>
    )
  }

  const resetData = () => {
    setTransactionData([]);
    setIncome([])
    setStartDate("");
    setEndDate("");
    setTotalIncome(0);
    setTotalExpenses(0);
  }

  const uploadData = () => {
    const newData = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      date: new Date(),
      expenses: totalExpenses,
      income: totalIncome,
      transactions: transactionData.map((transaction: { data: any; }) => (
        transaction.data
      ))
    }

    db.collection(`${UserId}`).doc("statements").get().then(
      (document) => {
        const updatedStatements = document.data()?.sources;
        updatedStatements.push(newData);
        db.collection(`${UserId}`).doc("statements").set({ sources: updatedStatements }).then(() => {
          updateIncomeData(updatedStatements, UserId);
          updateExpenseData(updatedStatements, UserId);
          resetData();
          setOpen(false)
        }).then(() => {
          logEvent(analytics, 'CSV_Upload_saved', { name: UserId.toString()});
          UpdateData();
        });
      }
    )
  }

  // const renderDateRange = () => {
  //   let d = endDate.split("/");
  //   return parseInt(d[1]) > 12 
  //   ? `${getDayDateByLocale(startDate, 'en-US')} - ${getDayDateByLocale(endDate, 'en-US')}`
  //   : `${getDayDateByLocale(startDate, 'en-GB')} - ${getDayDateByLocale(endDate, 'en-GB')}`
  // }

  return (
    <>
      <button
        type="button"
        className="font-medium bg-blue-700 py-2 px-4 rounded-md text-white hover:bg-blue-800 text-sm font-medium align-middle ml-4 mt-4"
        onClick={() => setOpen(true)}
      >
        Upload CSV
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-600 sm:mx-0 sm:h-10 sm:w-10">
                      <CloudUploadIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 text-left w-full lg:w-5/6">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-blue-600">
                        Upload CSV
                      </Dialog.Title>
                      <div className="mt-2 relative text-left">
                        <p className="text-sm text-gray-500 mb-4">
                          A large amount of <span className="text-gray-800 font-medium">Spending<span className="text-blue-600">Sight</span></span> is based off what comes in and out of your accounts.
                        </p>
                        <div className="bg-blue-50 p-2 rounded">
                          <InformationCircleIcon className="w-6 float-left mt-4 text-blue-700" />
                          <ul className="text-sm text-gray-500 list-disc ml-14">
                            <li>Please ensure the first row of your CSV contains headers.</li>
                            <li><code className="bg-gray-50 p-1 font-bold text-gray-700">Date</code> and <code className="bg-gray-50 p-1 font-bold text-gray-700">Amount</code> fields are <span className="text-red-400">mandatory.</span></li>
                            <li>Please ensure <code className="bg-gray-50 p-1 font-bold text-gray-700">Date</code> format is <code className="bg-gray-50 p-1 font-bold text-gray-700">MM-DD-YYYY</code></li>
                            <li>If you are unsure about the format of your CSV feel free to download our template below.</li>
                          </ul>
                        </div>

                        <div className="mt-4 mb-4">
                          <CSVReader
                            onDrop={handleOnDrop}
                            onError={handleOnError}
                            addRemoveButton
                            onRemoveFile={handleOnRemoveFile}
                            config={
                              {
                                header: true,
                                skipEmptyLines: 'greedy'
                              }
                            }
                            style={{
                              dropArea: {
                                borderColor: '#9ca3af',
                                borderWidth: '1px',
                                borderRadius: 10,
                                padding: 8,
                                fontSize: 13,
                                color: '#9ca3af'
                              },
                              dropAreaActive: {
                                borderColor: 'red',
                              },
                              dropFile: {
                                height: '38px',
                                width: 'auto',
                                background: '#fff',
                                paddingRight: '40px'
                              },
                              fileSizeInfo: {
                                display: 'none'
                              },
                              fileNameInfo: {
                                color: '#9ca3af',
                                backgroundColor: '#fff',
                                border: 'none',
                                fontSize: 14,
                                lineHeight: 1,
                                padding: '0 0.4em',
                              },
                              removeButton: {
                                color: '#1d4ed8',
                              },
                              removeButtonHover: {
                                color: 'red'
                              },
                              progressBar: {
                                backgroundColor: '#1d4ed8',
                              },
                            }}
                          >
                            Drop CSV file here or click to upload.
                          </CSVReader>
                        </div>
                        {transactionData.length > 0 && (
                          <>
                            {/* {startDate !== "" && endDate !== "" && 
                              <h2 className="text-blue-700 text-center text-xl font-semibold">
                                {renderDateRange()}
                              </h2>
                            } */}
                            <div className="grid grid-flow-col gap-4 pt-6 mb-8">
                              <div className="">
                                <h2 className="subpixel-antialiased text-sm text-gray-400 mb-2">Import Summary</h2>
                                <table className="table-auto w-full divide-y divide-gray-200 border">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th className="w-4 px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase ">Income Source(s)</th>
                                      <th className="w-4 px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase ">Income Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {income.map((source, index) => (
                                      <tr className="border" key={index}>
                                        <td className="px-4 py-2 text-left text-xs font-medium text-gray-500 whitespace-nowrap overflow-ellipsis overflow-hidden">
                                          {source.sourceName}
                                        </td>
                                        <td className="px-4 py-2 text-right text-sm font-medium text-blue-700 whitespace-nowrap overflow-ellipsis overflow-hidden">
                                          {source.amount}
                                        </td>
                                      </tr>
                                    ))}
                                    <tr className="border border-white h-8">
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr className="border border-white">
                                      <td className="px-4 py-1 text-left text-xs font-semibold text-gray-700 whitespace-nowrap overflow-ellipsis overflow-hidden uppercase">Total Income</td>
                                      <td className="px-4 py-1 text-right text-sm font-semibold text-blue-700 whitespace-nowrap overflow-ellipsis overflow-hidden">{totalIncome.toFixed(2)}</td>
                                    </tr>
                                    <tr className="border border-white">
                                      <td className="px-4 py-1 text-left text-xs font-semibold text-gray-700 whitespace-nowrap overflow-ellipsis overflow-hidden uppercase">Total Expenses</td>
                                      <td className="px-4 py-1 text-right text-sm font-semibold text-red-700 whitespace-nowrap overflow-ellipsis overflow-hidden">{totalExpenses.toFixed(2)}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <h2 className="subpixel-antialiased text-sm text-gray-400 mb-2">Import Breakdown</h2>
                            <table className="table-fixed w-full divide-y divide-gray-200 min-w-full border">
                              {renderTableHeader(transactionData)}
                              {renderTableBody(transactionData)}
                            </table>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse relative">
                  <Link to="/template.csv" target="_blank" download className="absolute left-2 rounded-md border shadow-sm px-4 py-2 text-blue-600 text-base font-medium bg-white hover:text-blue-800 hover:border-gray-300 hidden md:block sm:ml-3 sm:w-auto sm:text-sm"
                  >Download CSV Template</Link>
                  <button
                    type="button"
                    disabled={totalExpenses === 0 && totalIncome === 0}
                    className="disabled:bg-gray-200 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => uploadData()}
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setTransactionData([]);
                      setTotalExpenses(0);
                      setTotalIncome(0);
                      setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition.Root >
    </>
  )
}