import { useEffect, useRef, useState } from "react";
import { auth, signInWithGoogle } from "../firebaseSetup";
import { setupAccount } from "../utils/accounts";
import { useHistory } from "react-router-dom";
import { HeartIcon, LockOpenIcon } from '@heroicons/react/solid'

import googleSignInImg from '../assets/googleSignIn.png'; // Tell webpack this JS file uses this image

export default function SignUp() {
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [passwordLengthError, setPasswordLengthError] = useState(false);
    const [signupError, setSignupError] = useState<any>({});

    const history = useHistory();

    const getErrorMessageByCode = (errorCode: string) => {
        switch (errorCode) {
            case "auth/email-already-in-use": {
                return "It appears you already have an account...";
            }
            default: {
                //statements; 
                break;
            }
        }
    }

    const createAccount = async () => {
        if (passwordMatch && validEmail) {
            try {
                await auth.createUserWithEmailAndPassword(
                    emailRef.current!.value,
                    passwordRef.current!.value
                )
                    .then((user) => {
                        setupAccount(user)
                    })
                    .then(() => new Promise(resolve => setTimeout(resolve, 1000)))
                    .then(() => {
                        history.push("/dashboard");
                    })
            } catch (error) {
                setSignupError(error);
            }
        }
        if (!passwordMatch) {
            setPasswordMatchError(true);
        }
    };

    const createGoogleAccount = async () => {
        try {
            await signInWithGoogle()
                .then((user) => {
                    user.additionalUserInfo?.isNewUser && setupAccount(user);
                })
                .then(() => new Promise(resolve => setTimeout(resolve, 1000)))
                .then(() => {
                    history.push("/dashboard");
                })
        } catch (error) {
            // console.log(error)
            // setSignupError(error);
        }
    };

    const validEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const checkEmail = (email: string) => {
        if (validEmailPattern.test(email)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    const updatingEmail = (email: string) => {
        if (!validEmail) {
            if (validEmailPattern.test(email)) {
                setValidEmail(true);
            } else {
                setValidEmail(false);
            }
        }
    }

    const checkPasswordLength = (password: string) => {
        if (password.length < 6) {
            setPasswordLengthError(true)
        } else {
            setPasswordLengthError(false)
        }
    }

    useEffect(() => {
        if (password === confirmPassword) {
            setPasswordMatch(true)
        } else {
            setPasswordMatch(false)
        }
    }, [password, confirmPassword])

    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 z-10 relative">
            <div className="max-w-lg w-full space-y-8">
                <div>
                    <div className="mx-auto w-14 text-blue-700">
                        <HeartIcon />
                    </div>
                    <h2 className="my-6 text-center text-3xl font-bold text-blue-700">
                        Thanks for opting to join our Alpha Release!
                    </h2>
                    <div className="text-sm px-14 antialias">
                        <p className="mb-4">To signup with <span className="font-semibold text-blue-400">Plan</span><span className="font-semibold">Your</span><span className="font-semibold text-blue-400">Pay</span> we don't really require too much.</p>
                        <p>An email address and a password is all you need to start your Financial Journey.</p>
                    </div>
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label htmlFor="email-address" className="sr-only">Email address</label>
                        <input ref={emailRef} onBlur={(e) => { checkEmail(e.target.value) }} onChange={(e) => { updatingEmail(e.target.value) }} autoComplete="off" name="email" type="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-t-md focus:z-10 sm:text-sm " placeholder="Email address" />
                    </div>
                    <div>
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input ref={passwordRef} onChange={(e) => { setPassword(e.target.value) }} onBlur={(e) => { checkPasswordLength(e.target.value) }} autoComplete="off" name="password" type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" placeholder="Password" />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="sr-only">Password</label>
                        <input ref={confirmPasswordRef} onChange={(e) => { setConfirmPassword(e.target.value) }} autoComplete="off" name="confirmPassword" type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Confirm Password" />
                    </div>
                </div>
                {(!validEmail || passwordMatchError || passwordLengthError || signupError.code) && (
                    <div className="rounded px-4 py-4 bg-red-50 text-sm font-medium">
                        <p className="text-red-700">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-2 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                            There were errors in your signup</p>
                        <ul className="list-disc ml-8 mt-1 ml-12">
                            {!validEmail && (<li className="text-red-500">Please enter a valid email</li>)}
                            {passwordMatchError && (<li className="text-red-500">Passwords don't match</li>)}
                            {passwordLengthError && (<li className="text-red-500">Password must be atleast 6 characters long</li>)}
                            {signupError.code && (<li className="text-red-500">{getErrorMessageByCode(signupError.code)}</li>)}
                        </ul>
                    </div>
                )}
                <div>
                    <button disabled={!passwordMatch || passwordLengthError || password === "" || confirmPassword === ""} onClick={createAccount} className="group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-blue-700 disabled:bg-gray-400 ">
                        <div className="w-8 items-center pl-3 mr-1">
                            <LockOpenIcon />
                        </div>
                        Sign Up
                    </button>
                </div>
                <div className="text-center text-xs relative w-full pt-4 lg:pt-12">
                    <div className="absolute h-1 w-2/5 left-0 border-t top-6 lg:top-14 z-10"></div>
                    <div className="relative w-14 m-auto z-20 text-center text-white sm:text-gray-800">or</div>
                    <div className="absolute h-1 w-2/5 right-0 border-t top-6 lg:top-14 z-10"></div>
                </div>
                <div>
                    <button onClick={() => createGoogleAccount()} className="group relative flex justify-center py-2 px-4 text-sm font-medium mx-auto">
                        <img alt="Google Sign Up Button" src={googleSignInImg} />
                    </button>
                </div>
            </div>
        </div>
    )
};