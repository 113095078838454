export const getMonthBySeconds = (seconds: number) => new Date(seconds * 1000).toLocaleString('default', { month: 'short' });
export const getDayBySeconds = (seconds: number) => new Date(seconds * 1000).toLocaleString('default', { day: 'numeric' });
export const getYearBySeconds = (seconds: number) => new Date(seconds * 1000).toLocaleString('default', { year: 'numeric' });
export const getDateBySeconds = (seconds: number) => getMonthBySeconds(seconds) + " " + getDayBySeconds(seconds);
export const getDayDate = (date: Date) => {
    return new Date(date).toLocaleString('default', { day: 'numeric' }) + " " +
    new Date(date).toLocaleString('default', { month: 'short' });
}

export const getMonthByIndex = (dateIndex: number) => {
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"
    ];
    return monthNames[dateIndex];
}

export const getDayDateByLocale = (date: string, locale: string) => {
    let d = date.split("/");
    let utcDate = new Date();
    
    if(locale === 'en-GB'){
       utcDate = new Date(Date.UTC(parseInt(d[2]), parseInt(d[1]) -1, parseInt(d[0]), 0, 0, 0))
    }
    if(locale === 'en-US'){
        utcDate = new Date(Date.UTC(parseInt(d[2]), parseInt(d[0]) -1, parseInt(d[1]), 0, 0, 0))
    }

    return utcDate.toLocaleString(locale, { day: 'numeric' }) + " " +
    utcDate.toLocaleString(locale, { month: 'short' });
}
    
export const getUTCDate = (date: string) => {
    const splitDateStr = date.split("/");
    let utcDate;
    if ((parseInt(splitDateStr[0]) > 12) && splitDateStr[0].length !== 4) {
        // DD/MM/YYYY
        utcDate = new Date(Date.UTC(parseInt(splitDateStr[2]), parseInt(splitDateStr[1]) -1, parseInt(splitDateStr[0]), 0, 0, 0))
      } else if(splitDateStr[0].length === 4){
          // YYYY/MM/DD
          utcDate = new Date(Date.UTC(parseInt(splitDateStr[0]), parseInt(splitDateStr[1]) -1, parseInt(splitDateStr[2]), 0, 0, 0)) 
         
    } else {
          // MM/DD/YYYY
          utcDate = new Date(Date.UTC(parseInt(splitDateStr[2]), parseInt(splitDateStr[0]) -1, parseInt(splitDateStr[1]), 0, 0, 0))
    }
    return utcDate;
}