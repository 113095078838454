import { useRef, useState } from "react";
import { auth, signInWithGoogle } from "../firebaseSetup";
import { setupAccount } from "../utils/accounts";

import { ClipboardListIcon, LoginIcon } from '@heroicons/react/outline'
import googleSignInImg from '../assets/googleSignIn.png'; // Tell webpack this JS file uses this image

export default function LoginModal() {
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [loginError, setLoginError] = useState<any>({});
    const [validEmail, setValidEmail] = useState(true);

    const signIn = async () => {
        try {
            await auth.signInWithEmailAndPassword(
                emailRef.current!.value,
                passwordRef.current!.value
            );
        } catch (error) {
            setLoginError(error)
        }
    };

    const signInWithGoogleAccount = async () => {
        try {
            await signInWithGoogle()
                .then((user) => {
                    user.additionalUserInfo?.isNewUser && setupAccount(user);
                })
        } catch (error) {
            // console.log(error)
            // setSignupError(error);
        }
    };

    const getErrorMessageByCode = (errorCode:string) => {
        console.log(errorCode)
        switch (errorCode) {
            case "auth/invalid-email": {
                return "Invalid email/password";
            }
            case "auth/wrong-password": {
                return "Invalid email / password";
              }
            default: {
              //statements; 
              break;
            }
          }
    }

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {
          signIn();
        }
      }

    const validEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const checkEmail = (email: string) => {
        if (validEmailPattern.test(email)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    return (
        <div className="min-h-fit rounded-md w-full md:w-1/2 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 md:mt-20 m-auto relative z-10">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <div className="mx-auto w-20 text-blue-700">
                        <ClipboardListIcon />
                    </div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-blue-700">
                        Sign in to your account
                    </h2>
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label htmlFor="email-address" className="sr-only">Email address</label>
                        <input ref={emailRef} onBlur={(e) => { checkEmail(e.target.value) }} autoComplete="off" name="email" type="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
                    </div>
                    <div>
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input ref={passwordRef} onKeyDown={handleKeyDown} autoComplete="off" name="password" type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
                    </div>
                </div>
                {(loginError.code || !validEmail) && (
                    <div className="rounded px-4 py-4 bg-red-50 text-sm font-medium text-red-700">
                        <p>There were errors in your login attempt</p>
                        <ul className="list-disc ml-8 mt-1 ml-12">
                            {!validEmail && (<li className="text-red-500">Please enter a valid email</li>)}
                            {validEmail && loginError && (<li className="text-red-500">{getErrorMessageByCode(loginError.code)}</li>)}
                        </ul>
                    </div>
                )}
                <div>
                    <button onClick={signIn} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <div className="w-8 items-center pl-3 mr-1">
                            <LoginIcon />
                        </div>
                        Sign In
                    </button>
                </div>
                <div className="text-center text-xs relative w-full pt-4 lg:pt-12">
                    <div className="absolute h-1 w-2/5 left-0 border-t top-6 lg:top-14 z-10"></div>
                    <div className="relative w-14 m-auto z-20 text-center text-white md:text-gray-800">or</div>
                    <div className="absolute h-1 w-2/5 right-0 border-t top-6 lg:top-14 z-10"></div>
                </div>
                <div>
                    <button onClick={() => signInWithGoogleAccount()} className="group relative flex justify-center py-2 px-4 text-sm font-medium mx-auto">
                        <img alt="Google Sign Up Button" src={googleSignInImg} />
                    </button>
                </div>
                
                <div>
                    <a href="/signup" className="group relative w-full flex justify-center md:pt-2 sm:py-2 px-4 border border-transparent text-sm rounded-md text-white sm:text-gray-600">
                        Don't have account?
                    </a>
                </div>
            </div>
        </div>
    )
};