import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { auth } from "./firebaseSetup";
import LoginModal from "./components/loginModal";
import { Homepage } from "./pages/homepage";
import SignUp from "./pages/signup";
import Dashboard from "./pages/dashboard";

import logo from './assets/logo.svg';
import waves from './assets/waves.svg'; // Tell webpack this JS file uses this image

import { CheckCircleIcon } from "@heroicons/react/outline";
import { Button } from "react-bootstrap";


function App() {
  const [showLoading, setShowLoading] = useState(true);

  const user = useContext(AuthContext);

  const signInDemo = async () => {
      await auth.signInWithEmailAndPassword('demo@planyourpay.com', 'password')
      .then(() => {
        console.log('go to dashboard')
      })
      .catch((error) => {
        console.log('didnt work');
      });
  }

  const signOut = async () => {
    await auth.signOut();
  };

  useEffect(() => {
    let delay = setTimeout(() => setShowLoading(false), 1 * 1000);

    return () => {
      clearTimeout(delay);
    };
  }, []);


  return (
    <Router>
      <div className="bg-gray-50 min-h-screen">
        <div className="w-full top-0 bg-blue-700 relative mb-10">
          <div className="max-w-7xl mx-auto px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <NavLink className="flex-shrink-0 text-white font-bold antialiased relative" to="/">
                  <img src={logo} alt="plan your pay logo" className="w-8 float-left absolute top-0 rotate-90"/>
                  <span className="text-blue-400 pl-10">Plan</span><span>Your</span><span className="text-blue-400">Pay</span>
                </NavLink>
                <div>
                  <div className="ml-4 lgml-10 flex items-baseline space-x-4">
                    <NavLink className="text-white px-3 py-2 rounded-md text-sm font-medium antialiased hidden md:block" exact activeClassName="font-bold" to="/">
                      HOME
                    </NavLink>
                    <NavLink className="text-white px-3 py-2 rounded-md text-sm font-medium left-2 relative lg:left-0" exact activeClassName="font-bold" to="/dashboard">
                      DASHBOARD
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
                {user &&
                  <div>
                    <span className="hidden md:block float-left mr-8 mt-3 text-xs text-white"><CheckCircleIcon className="text-blue-300 w-4 float-left mr-2"/>{user.email}</span>
                    <button onClick={signOut} className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-white text-blue-700 hover:bg-gray-50 focus:outline-none">
                      Sign Out
                    </button>
                  </div>
                }
                {!user &&
                <>
                  <a href="/dashboard" className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:text-gray-50 focus:outline-none">
                    Sign In
                  </a>
                  <a href="/signup" className="hidden md:block flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-white text-blue-700 hover:bg-blue-600 hover:text-white focus:outline-none">
                    Sign Up
                  </a>
                  </>}
              </div>
            </div>
          </div>

        </div>
        {
          <Switch>
            <Route exact path="/">
              <div className="max-w-7xl mx-auto p-10 mt-16 relative z-10">
                <Homepage />
              </div>
            </Route>
            {/* <Route exact path="/pricing">
              <div className="max-w-7xl mx-auto p-10 mt-16 relative z-10">
                <Pricing />
              </div>
            </Route> */}
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/dashboard">
              <div className="max-w-7xl mx-auto px-8 pb-10">
                {showLoading ? (
                  <div className="fixed top-0 left-0 w-full h-full bg-gray-50">
                    <span className="absolute top-1/3 left-1/2 animate-spin rounded-full p-2 text-blue-600 text-sm">
                      <svg className="animate-spin h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    <p className="absolute top-1/3 left-1/2 mt-16 text-blue-600 text-sm">Loading...</p>
                  </div>
                ) : (!user ? (
                  <LoginModal />
                ) : (
                  <>
                    <Dashboard />
                  </>
                ))}
              </div>
            </Route>
          </Switch>
        }
      </div>
    </Router>
  );
}

export default App;