import { PencilIcon } from "@heroicons/react/outline";
import React from "react";
import { db } from "../firebaseSetup";
import { toCurrency } from "../utils/currency";

type Props = {
    UserId?: string
    AccountTitle: string,
    AccountData: any,
    showAverage?: boolean,
    isEditable?: boolean,
    reverseAvgGoal?: boolean;
};

export const AccountBalance: React.FC<Props> = ({
    UserId,
    AccountTitle,
    AccountData,
    showAverage,
    reverseAvgGoal,
    isEditable
}) => {

    const updateEditable = (accountName: string, value: string) => {
        db.collection(`${UserId}`).doc("savings").get().then(
            (document) => {
                // get current plan
                const updatedAccount = document.data()?.sources;
                updatedAccount.forEach((account: { name: string; balance: number; }) => {
                    if (account.name === accountName) {
                        account.balance = +parseFloat(value).toFixed(2);
                    }
                });
                // // set document
                db.collection(`${UserId}`).doc("savings").set({ sources: updatedAccount });

            }
        )
    }

    const renderArrow = (account: { balance: number; average: number }, goalColor: string) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 float-left mr-2 mt-1" fill="none" viewBox="0 0 24 24" stroke={goalColor}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 11l5-5m0 0l5 5m-5-5v12" id="upArrow" />
            </svg>
        );
    };


    const renderAverageData = (account: { balance: number; average: number }) => {
        let goalColor = '';

        if(!reverseAvgGoal && (account.balance > account.average)){
            goalColor = 'green';
        } 
        if (reverseAvgGoal && (account.balance < account.average)) {
            goalColor = 'green'
        }
        
        if(!reverseAvgGoal && (account.balance < account.average)){
            goalColor = 'red';
        }
        if (reverseAvgGoal && (account.balance > account.average)) {
            goalColor = 'red'
        }

        return showAverage ? (
            <>
                <div className="subpixel-antialiased text-2xl text-blue-700 font-medium">{toCurrency(account.average)}</div>
                <div className="subpixel-antialiased text-xs font-medium text-gray-500 mt-2">Last Month</div>
                <div className={`subpixel-antialiased text-md font-medium text-gray-500`}>
                    {account.balance !== account.average && renderArrow(account, goalColor)}
                    {toCurrency(account.balance)}</div>
            </>
        ) : (
            <div className="subpixel-antialiased text-2xl text-blue-700 font-medium">{toCurrency(account.balance)} </div>
        )
    }

    return (
        <>
            <h2 className="subpixel-antialiased text-xs text-gray-500 font-medium"> {showAverage ? `Avg ${AccountTitle}` : AccountTitle}</h2>
            {AccountData && AccountData.length > 0
                ? (AccountData.map((account: { name: string; balance: number; average: number }, index: number) => (
                    <div className="pb-2" key={index}>
                        {account.name && (<h4 className="subpixel-antialiased text-xs text-gray-400 mt-2">{account.name}</h4>)}
                        {isEditable ? (
                            <div className="relative group">
                                <input
                                    type="text"
                                    className="focus:outline-none block w-full border-gray-300 rounded-md subpixel-antialiased text-2xl text-blue-700 font-medium placeholder-blue-700"
                                    placeholder={toCurrency(account.balance)}
                                    onChange={(e) => updateEditable(account.name, e.target.value)}
                                    defaultValue={toCurrency(account.balance)}

                                />
                                <PencilIcon className="float-right w-4 absolute right-2 top-1 text-gray-300 group-hover:text-gray-500" />
                            </div>
                        ) : (
                            renderAverageData(account)
                        )}
                    </div>
                )
                )) : (
                    <div className="pt-4 text-xs text-center text-blue-700 font-medium">No Monthly Data</div>
                )}
        </>
    )
};
